:root {
  --themeclr: #ec6043;
  --themeclr1: #f5593e;
  --second: #f8c006;
  --txtgray: #7a7b7d;
  --bordclr: #b0b0b0;
  --textgray: #484848;
  --adminblue: #3655b6;
  --txtblue: #13375b;
  --orange: #ec6043;
  --blue: #1469e2;
  --lite-black-heading: #3e3e3e;
  --lite-black-sub-heading: #1b1b1b;
  --white: #fff;
  --black: #000;
}

* {
  padding: 0;
  margin: 0;
}

body {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Inter";
}

.cmn-bg-orange {
  background-color: var(--themeclr1) !important;
  color: var(--white) !important;
  outline: none;
  border: 1px solid var(--themeclr1) !important;
}
.cmn-bg-orange:hover {
  background-color: var(--black) !important;
  border: 1px solid var(--black) !important;
}

.cmn-bg-orange-bdr {
  background-color: transparent !important;
  color: var(--themeclr1) !important;
  outline: none;
  border: 1px solid var(--themeclr1);
}
.cmn-bg-orange-bdr:hover {
  background-color: var(--themeclr1) !important;
  border: 1px solid var(--themeclr1);
  color: var(--white);
}

.cmn-bg-black {
  background-color: var(--black) !important;
  color: var(--white) !important;
  outline: none;
  border: 1px solid var(--black);
}
.cmn-bg-black:hover {
  background-color: var(--themeclr1) !important;
  border: 1px solid var(--themeclr1);
}

.cmn-bg-black-bdr {
  background-color: var(--black) !important;
  color: var(--white) !important;
  outline: none;
  border: 1px solid var(--black) !important;
}
.cmn-bg-black-bdr:hover {
  background-color: transparent !important;
  border: 1px solid var(--black) !important;
  color: var(--black) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--orange);
  color: var(--white);
}

.noborder {
  border: 0 !important;
}

select.form-control {
  appearance: auto;
}

.txtblue {
  /* color: var(--txtblue); */
  color: var(--lite-black-heading);
}

.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}

.fw-bold {
  font-weight: bold;
}

.f-10 {
  font-size: 10px;
}
.f-11 {
  font-size: 11px;
}
.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-size: 15px;
}
.f-16 {
  font-size: 16px;
}
.f-17 {
  font-size: 17px;
}
.f-18 {
  font-size: 18px;
}
.f-19 {
  font-size: 19px;
}
.f-20 {
  font-size: 20px;
}
.f-21 {
  font-size: 21px;
}

.lh-19 {
  line-height: 19px;
}
.lh-20 {
  line-height: 20px;
}
.lh-21 {
  line-height: 21px;
}

.orange-txt {
  color: var(--themeclr1);
}

.btn-theme:hover,
.btn-theme:focus,
.btn-theme:focus-visible,
.btn-theme:active {
  background-color: var(--lite-black-sub-heading) !important;
  border-color: var(--lite-black-sub-heading) !important;

  color: white !important;
}

.host-exp-nxt-btn {
  background-color: var(--orange);
}

.text-heading-large {
  color: var(--lite-black-heading);
  font-size: 30px;
}

.text-heading {
  color: var(--lite-black-heading);
  font-size: 20px;
}

.text-heading-medium {
  color: var(--lite-black-heading);
  font-size: 20px;
}

.close_modal {
  /* padding: 0px !important; */
  /* height: 10px; */
}

/* .close_modal .fa-times {
  background: white;
  width: 23px;
  border-radius: 50%;
  padding: 10px;
}  */
.modal-header {
  padding: 0px !important;
}

.text-heading-small {
  color: var(--lite-black-heading);
  font-size: 20px;
}

.text-sub-heading {
  color: var(--lite-black-sub-heading);
  font-size: 20px;
}

.text-sub-heading-medium {
  color: var(--lite-black-sub-heading);
  font-size: 16px;
}

.adminblue {
  /* color: var(--adminblue); */
  color: var(--orange);
}

.form-control:focus {
  border-color: #ced4da;
}

li {
  list-style-type: none;
}

.liststyledots li {
  list-style-type: disc;
}

.textgray {
  color: var(--textgray);
}

.liststyle li {
  list-style-type: disc;
}

.max-width-100 {
  max-width: 100px;
}

.themebg {
  /* background-image: linear-gradient(45deg, #082e53, #f6be09); */
}

.mh-100vh {
  min-height: 100vh;
}

.mh-100vh-header {
  min-height: calc(100vh - 138px);
}

.mh-100vh-filterheader-footer {
  min-height: calc(100vh - 252px);
}

.themeclr {
  color: var(--themeclr);
}
.themeclr1 {
  color: var(--themeclr1);
}

.themehr {
  background-color: #858585;
}

.nounder {
  text-decoration: none;
}

.nolink {
  cursor: auto !important;
}

.upper {
  text-transform: uppercase;
}

/* common */
.themebord {
  border-color: #dadada;
}

.graytxt {
  color: #717171;
}

.flex-1 > * {
  flex: 1;
}

.jc-end {
  justify-content: end;
}

.jc-center {
  justify-content: center;
}

.jc-between {
  justify-content: space-between;
  align-items: center;
}

.align-center {
  align-items: center;
}

.btn.disable {
  cursor: not-allowed !important;
}

a.navbar-brand img,
.logo img {
  max-width: 145px !important;
  max-height: 50px !important;
}

.bluebg {
  background: #082e53;
  /* background-color: var(--orange); */
}

.line-height-2 {
  line-height: 2;
}

.br-10 {
  border-radius: 10px !important;
}

.form-switch .form-check-input {
  height: 20px;
}

p {
  margin-bottom: 0;
}

.bord {
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mt-78 {
  margin-top: 78px !important;
}

.f-50 {
  font-size: 50px;
}

.form-check {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}

.logincheckbox .form-check {
  flex-direction: unset;
  width: unset;
  justify-content: unset;
}

.form-check-input:checked {
  background-color: var(--themeclr);
  border-color: var(--themeclr);
}

.countbtns button:hover {
  border-color: gray;
}

button.nofocus:focus {
  box-shadow: none !important;
}

.mb-90 {
  margin-bottom: 90px;
}

.height-100 {
  height: 100%;
}

.min-height-100vh {
  /* min-height: 100vh; */
}

.w-full {
  width: 100%;
}

input::placeholder {
  font-size: 14px;
}

.continue-exploring button {
  width: 275px;
  height: 50px;
  background: #ec6043;
  border: none;
  outline: none;
  border-radius: 0px;
}

.continue-exploring button .btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background: #ec6043;
  border: none;
  outline: none;
}

.continue-exploring button:hover {
  background: #ec6043;
  border: none;
  outline: none;
}

.btn-theme {
  background: var(--themeclr);
  color: var(--second);
}

.themeclr {
  /* color: var(--themeclr); */
  /* color: #1b1b1b; */
  color: #000;
}

.card-amount-dis {
  color: #f5593e;
}

.cus_hr {
  color: #958f8f;
  height: 2px !important;
}

.txtgray {
  /* color: var(--txtgray); */
  color: #aaa;
}

.cus_txt_clr {
  color: #000 !important;
  font-weight: 500;
}

.bordclr {
  color: var(--bordclr);
}

.btn-theme {
  background: #ec6043 !important;
  color: #fff !important;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
  min-width: 100px;
  border-radius: 8px;
  border-color: #ec6043 !important;
  color: white;
  border-radius: 0px;
}

.btnbord {
  border-radius: 8px;
}

select:focus,
input:focus,
textarea:focus {
  box-shadow: none !important;
}

.tripcancel .prof,
.paymentss .prof {
  width: 50px;
  margin-right: 5px;
}

.chatinput textarea {
  height: auto !important;
  border-radius: 3px 0 0 3px;
}

.chatinput button {
  background: #f1f1f1;
  padding: 11px !important;
  width: 50px;
  border-radius: 0 3px 0 3px;
}

.chatinput {
  position: sticky;
  bottom: 0;
  width: 100%;
}

img.attached {
  max-width: 200px;
}

li.message {
  text-align: right;
}

.uploadfile input {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  z-index: 999;
}

.uploadfile {
  position: relative;
  max-width: 200px;
  aspect-ratio: 1/1;
  border: 1px dashed #e6e6e6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadfile span {
  font-size: 82px;
  color: gray;
}

.uploadfile img {
  max-width: 200px;
  aspect-ratio: 1/1;
  position: absolute;
}

.disputechat-lists {
  max-height: 400px;
  position: relative;
  overflow-y: auto;
}

.disputechat-lists ul li .w-full {
  border: 1px solid #d8d8d8;
  padding: 8px;
  border-radius: 0px;
}

.info-box {
  background: #c5dbf9;
  padding: 5px 15px;
  border-radius: 18px;
  font-size: 14px;
  color: #7aacf0;
}

.success-box {
  background: var(--themeclr1);
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  color: var(--white);
}

.pending-box {
  background: #f5b159;
  padding: 5px 15px;
  border-radius: 18px;
  font-size: 14px;
  color: #985d14;
}

.checklist img.hostimg {
  height: 200px;
  width: 100%;
}

.fa.favstar {
  color: yellow;
}

.continue-social span button,
.continue-social div button {
  width: 200px;
  padding: 7px;
  border-radius: 0px !important;
  background: transparent;
  color: #000;
  height: 40px;
}

.kep-login-facebook {
  display: flex !important;
  color: #3e3e3e;
  align-items: center;
  font-size: 13px;
  width: 200px !important;
  height: 41px !important;
}

.googlelogin button > div {
  justify-content: center;
  width: 192px;
  margin: auto;
}

.reviewcard .badge {
  background: #000;
  color: #fff;
  font-weight: 600;
  border-radius: 0px;
}

.share button.btn {
  font-size: 14px;
}

.expsteps {
  width: 100%;
  min-width: 374px;
  overflow-x: auto;
  white-space: nowrap;
}

.expsteps .steps {
  gap: 15px;
}

.share button.btn:hover {
  color: var(--themeclr);
  background: transparent;
}

.continue-social span {
  display: block;
  width: 100%;
}

.country_card .dropdown-item:focus,
.country_card .dropdown-item:hover {
  background-color: transparent;
}

.payment button {
  width: calc(100% - 62px);
  background: transparent;
  color: #000;
  text-align: left;
  border-color: #d8d8d8;
}

div#fav button {
  background: transparent;
  border: none;
  color: #c7c7c7;
}

div#fav .fa-star.active {
  color: var(--themeclr) !important;
}

form.hideinput input {
  position: absolute;
  /* height: 100%;
  width: 100%; */
  height: 84%;
  width: 94%;
  top: 0;
  z-index: 9;
  opacity: 0;
}

.imageaction.dropdown {
  position: relative;
  z-index: 99;
}

.payment button:hover,
.payment button:focus,
.payment button:active {
  background: transparent;
  color: #000;
  border-color: #d8d8d8;
}

.googlelogin button {
  margin: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
  font-size: 14px !important;
  text-transform: uppercase;
  font-weight: 900;
}

/* listing */
.listings img {
  width: 55px;
  height: 45px;
  border-radius: 5px;
}

.listings div[role="row"] {
  padding: 20px 0;
}

.listings div[role="row"] p {
  margin-left: 5px;
}

.listings div[role="row"] > div:first-child {
  min-width: 250px;
}

.listings div[role="row"] > div:nth-child(2) {
  min-width: 150px;
  max-width: 150px;
}

.listings div[role="row"] > div:nth-child(3) {
  min-width: 150px;
}

.listings div[role="row"] > div:nth-child(5) {
  min-width: 150px;
}
.listings div[role="row"] > div:nth-child(4) {
  min-width: 150px;
}
.listings div[role="row"] > div:nth-child(6) {
  min-width: 150px;
}
.listings div[role="table"] > div:first-child {
  text-transform: uppercase;
  color: gray;
  font-weight: 600;
}

.listings nav span {
  display: none;
}

#pagination-previous-page[aria-disabled="false"],
#pagination-next-page[aria-disabled="false"] {
  background: var(--themeclr);
}

#pagination-next-page[aria-disabled="false"] svg,
#pagination-previous-page[aria-disabled="false"] svg {
  fill: #fff;
}

.x-btn {
  background: transparent;
  border: 0;
  font-size: 20px;
  width: 35px;
  height: 35px;
}

.cancelbook .img {
  max-width: 100px;
  border-radius: 5px;
  margin-right: 5px;
  aspect-ratio: 1.5/1;
}

.mngspace .accordion-header button {
  background-color: rgb(248, 248, 248) !important;
  box-shadow: none;
  color: #000 !important;
}

.mngspace .accordion-header button:after {
  display: none;
}

.mngspace .accordion-item {
  margin-bottom: 15px;
}

.mngspace .accordion-item,
.mngspace .accordion-header {
  border: 0;
}

.mngspace .accordion-collapse {
  border-left: 1px solid #dddddd;
  margin: 10px;
}

.mngspace .accordion-body {
  padding-left: 0;
  padding-bottom: 0;
}

.mngspace .accordion-body ul {
  padding-left: 0;
}

#amenities .d-grid {
  grid-template-columns: 50% 50%;
  row-gap: 5px;
  column-gap: 10px;
}

#amenities .d-grid p {
  font-size: 14px;
  color: #6c757d;
  white-space: nowrap;
}

.mngspace .accordion-body li a {
  border-left: 2px solid transparent;
  font-size: 15px;
  font-weight: 300;
  color: gray !important;
}

.mngspace .accordion-body li a.active {
  border-left: 2px solid #1e1d1d;
  color: #000 !important;
  font-weight: 500;
}

.mngspace .accordion-body li a {
  padding-left: 10px;
}

.room_scroll button.active {
  border: 1px solid #000;
}

.calendar_div_scroll_filter .card_calendar.active {
  border: 2px solid #000;
}

.sharing img {
  height: 80px;
  width: 80px;
  border-radius: 5px;
}

.listingmodals input[type="checkbox"],
.listingmodals input[type="radio"] {
  height: 20px;
  width: 20px;
  margin-top: 0;
}

.listinglist img {
  max-width: 65px;
  max-height: 50px;
}

.listinglist-drop {
  width: 500px;
}

.listingmodals .themehr {
  margin: 38px 0;
}

.listingmodals .form-check {
  align-items: center;
}

.scenic .form-check {
  margin-bottom: 15px;
}

.hignlights ul li.active {
  border: 1px solid #000;
}

.circlebtn.active {
  background: black !important;
  color: #fff;
}

.amenities-lists .circlebtn {
  background: #fff;
}

.spinner {
  width: 17.6px;
  height: 17.6px;
  animation: spinner-o824ag 1.2s infinite linear;
}

.spinner div {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f8c006;
  border-radius: 50%;
  animation: spinner-vse6n7 1.5s infinite ease;
}

.spinner div:nth-child(1) {
  --rotation: 90;
}

.spinner div:nth-child(2) {
  --rotation: 180;
}

.spinner div:nth-child(3) {
  --rotation: 270;
}

.spinner div:nth-child(4) {
  --rotation: 360;
}

.spacelists .accordion-item {
  margin-top: 13px;
  border-top: 1px solid #dfdfdf;
}

.opacity.editprofbtn {
  opacity: 0.5;
  pointer-events: none;
}

.reviews img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 9px;
}

.prof-sec .headerblocks {
  justify-content: space-between;
}

.profpagetop {
  padding-top: 100px;
}

.profile.profpagetop {
  min-height: calc(100vh - 82px);
}

.editimg input {
  position: absolute;
  width: 108px;
  opacity: 0;
}

.profreviews p {
  font-size: 14px;
}

.message-s img {
  width: 45px;
  height: 45px;
  margin-right: 8px;
}

.message-s ul {
  padding-left: 0;
  margin-top: auto;
}

.mobilereseve {
  display: none;
}

.msg {
  display: flex;
  align-items: baseline;
  overflow-y: auto;
  height: calc(100vh - 262px);
  flex-direction: column;
}

.templatemsg {
  font-size: 27px;
  color: #000;
}

.msg ul {
  /* width: 90%; */
  width: 99%;
  padding: 0px 18px;
}

.msg ul li {
  margin-bottom: 15px;
}

.msg ul li p {
  color: gray;
}

.reserve-side {
  border-left: 1px solid #ebebeb;
}

@keyframes spinner-vse6n7 {
  0%,
  100% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
  }

  50% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
  }
}

@keyframes spinner-o824ag {
  to {
    transform: rotate(360deg);
  }
}

/* view */
.navpos select#countrycode {
  border: 0;
  font-size: 14px;
  padding: 0;
}

.cancelbox {
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 15px;
}

.listcard .imgdiv {
  background: #d8d8d8;
}

.listcard {
  margin-bottom: 37px;
}

.favorite .fa {
  font-size: 12px;
  color: var(--themeclr);
}

.imgdiv img {
  height: 300px;
  object-fit: contain;
}

/* insight modal */
.todos ul li button {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  width: 100%;
  border: none;
  background: transparent;
  padding-top: 15px;
}

.d-scroll > div > div:last-child {
  display: none;
}

.todos ul li button {
  border-bottom: 1px solid #e5e5e5;
}

.todos ul,
.completed ul {
  padding-left: 0;
}

.todos ul li .icon,
.completed ul li .icon {
  margin-right: 10px;
}

.completed ul li {
  margin-bottom: 12px;
}

/* insights tab */
.adapt .lists {
  height: 100%;
}

.insight-tab .nav-pills .nav-link {
  padding-bottom: 15px;
}

.insight-tab .nav-pills .nav-link.active,
.insight-tab .nav-item {
  background: #f1f1f100;
  color: black;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}

.insight-tab .nav-item {
  margin-right: 15px;
}

.insight-tab .nav-pills .nav-link.active {
  border-bottom: 2px solid gray;
  border-radius: 0;
}

.insight-tab .owl-nav {
  position: absolute;
  top: -57px;
  right: 46px;
}

.insight-tab .owl-nav button:hover {
  background: transparent !important;
  color: gray !important;
}

.insight-tab .owl-nav button span {
  background: white;
  height: 30px;
  width: 30px;
  display: block;
  border-radius: 50%;
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgb(0 0 0 / 18%) 0px 2px 4px !important;
}

.insight-tab .nav-item a {
  background: #f1f1f100;
  color: gray;
  padding-left: 0;
  padding-right: 0;
}

.superhosttab .nav-pills {
  border-bottom: 0 !important;
}

.superhosttab .nav-pills .nav-link.active {
  background: var(--themeclr);
  color: white;
  border-radius: 22px;
  text-align: center;
}

.superhosttab .nav-pills .nav-link {
  padding: 5px 26px;
  border-radius: 22px;
  min-width: 129px;
}

.hostreward .owl-nav {
  top: -72px;
}

.bordbox {
  /* border: 1px solid rgb(235, 235, 235); */
  /* background: #d8f2ff;*/
  /* padding: 15px;  */
  border-radius: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.25);
}

.faclose {
  color: white;
  background: black;
  height: 17px;
  width: 17px;
  text-align: center;
  line-height: 1.6;
  border-radius: 50%;
  margin-right: 5px;
  font-size: 10px;
}

.reviewcard img {
  max-width: 100px;
  aspect-ratio: 1/1;
  border-radius: 10px;
  margin-left: 10px;
}

.insight-tab .nav.nav-pills {
  border-bottom: 1px solid #dadada;
  max-width: 100%;
  overflow: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.insight-cont,
.bggray {
  background: #f7f7f7;
}

.insight-cont .owl-carousel {
  border-top: 1px solid lightgrey;
  padding-top: 18px;
}

.lists .progress,
.modal-prog .progress {
  max-width: 160px;
  height: 6px;
  width: 100%;
}

.modal.show {
  padding-left: 0 !important;
}

.todos ul li .icon,
.completed ul li .icon {
  background: var(--themeclr);
  height: 30px;
  width: 30px;
  padding: 7px;
  border-radius: 50%;
}

.todos ul li:last-child button {
  border-bottom: 0;
}

.completed ul li .icon {
  background: #fff;
  border: 1px solid #c3c3c3;
}

.lists img {
  background: var(--themeclr);
  height: 30px;
  width: 30px;
  padding: 7px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.bord-icon {
  height: 30px;
  width: 30px;
  padding: 7px;
  margin-bottom: 15px;
  border-radius: 50%;
  border: 1px solid var(--themeclr);
}

.lists button.btn.btn-outline-dark {
  border-radius: 9px;
  font-size: 12px;
}

.shadowbox {
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px !important;
}
.shadowbox1 {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

/* account */
.form-switch .form-check-input:checked {
  background-color: var(--themeclr);
}

.li-style-disc {
  padding-left: 20px;
}

.li-style-disc li {
  list-style-type: disc;
}

.li-style-disc li a {
  text-decoration: none;
  font-size: 14px;
}

.personalinfo .insight-tab {
  min-height: 70vh;
}

.grayplace label,
.grayplace option {
  color: gray;
  font-size: 14px;
}

.attachimg img {
  width: 32%;
  aspect-ratio: 1.5/1;
}

.attachimg ul li {
  display: inline-block;

  font-size: 12px;
  margin-right: 10px;
}

.attachimg ul li button {
  padding: 0;
}

.attachimg ul li button.btn i {
  background: #e8e8e8;
  font-size: 10px;
  padding: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: gray;
}

.choosefiles {
  position: relative;
}

.choosefiles input {
  position: absolute;
  width: 106px;
  opacity: 0;
}

/* .choosefiles {
  width: 106px;
  height: 47px;
} */
.attachinput div:first-child input {
  border-radius: 5px 5px 0 0;
}

.attachinput div:last-child input {
  border-top: none;
  border-radius: 0 0 5px 5px;
}

.verified {
  background: #c4c4c4;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 2;
}

.verified.fa {
  background: var(--themeclr);
  color: white;
}

.loginmodal .themecheck {
  align-items: center;
  font-size: 14px;
}

.loginmodal .themecheck input {
  height: 25px !important;
  width: 25px !important;
}

.edited .btn_grey {
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
  min-width: 100px;
  border-radius: 8px;
}

ol.breadcrumb a {
  color: #000;
  font-size: 14px;
}

.personalinfo li button.btn-link {
  color: #000;
  font-size: 14px;
}

.show .editedcont {
  display: block;
}

.editedcont {
  display: none;
}

.acc-box {
  /* padding: 15px;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px !important;
  border-radius: 8px;
  margin-bottom: 30px;
  color: #000; */
  width: 384px;
  height: 493px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.15);
}

.acc-box h6 {
  color: var(--orange);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 107.143% */
}

.acc-box p {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  max-width: 90%;
  margin: 0 auto;
  line-height: 28px;
  /* 116.667% */
}

.acc-box button {
  width: 191px;
  height: 48px;
  background-color: #ec6043;
  color: var(--white);
  font-weight: 500;
  border: none;
  outline: none;
  cursor: pointer;
}

.acc-box button:hover {
  background-color: #1b1b1b;
  color: var(--white) !important;
}

.take-care-of a:hover {
  color: #ec6043;
}

.user-icon-img {
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 50%;
}

@media (max-width: 576px) {
  .acc-box {
    width: 278px;
    /* height: 342px; */
    height: unset !important;
  }

  .custom-address-country-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .acc-box h6 {
    font-size: 18px;
  }

  .acc-box p {
    font-size: 18px;
  }

  .acc-box button {
    width: 118px;
    padding: 5px;
  }

  .need-to-deactive {
    font-size: 17px !important;
  }

  .take-care-of a {
    font-size: 17px !important;
  }

  .user-icon-img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}

@media (max-width: 576px) {
}

.need-to-deactive {
  color: var(--lite-black-sub-heading);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 150% */
}

.take-care-of a {
  color: var(--lite-black-sub-heading);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 150% */
}

.acc-box button:hover {
  color: black;
}

div#hostaccount .col-lg-4 a {
  text-decoration: none;
}

.acc-box span {
  font-size: 22px;
  margin-bottom: 22px;
}

/* .acc-box p {
  font-size: 14px;
  color: gray;
} */
.nocenterhead .centerhead,
.nocenterhead .mobile-filter {
  visibility: hidden;
  padding: 0;
}

/*  modal */
.dobselect select {
  font-size: 12px;
}

.dobselect {
  gap: 12px;
}

ul.li-inline-header li > button {
  pointer-events: none;
}

.finishform .doubleinput .inputs.top input {
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
}

.finishform .doubleinput .inputs.bot input {
  border-radius: 0 0 5px 5px;
}

.finishform input {
  padding-top: 19px;
  padding-bottom: 8px;
}

.finishform input:focus {
  box-shadow: none;
}

.inputs label,
.inputs p {
  position: absolute;
  font-size: 12px;
  padding-left: 12px;
  padding-top: 6px;
  color: #9f9f9f;
}

.confirm {
  display: flex;
  gap: 15px;
}

form.confirm input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #c8c8c8;
}

form.confirm input.otp:focus {
  box-shadow: none;
}

.continue-social img {
  width: 28px;
  height: auto;
  margin-right: 15px;
  position: absolute;
  left: 22px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.continue-social button {
  position: relative;
}

.kep-login-facebook .fa {
  font-size: 22px;
}

/* .continue-social button:hover {
  background: #f3f4f4;
  border-color: #000;
  color: black;
} */
.continue-social button {
  font-size: 12.5px;
}

.or {
  position: relative;
}

.or span {
  position: absolute;
  font-size: 12px;
  background: white;
  line-height: 3;
  left: 0;
  right: 0;
  width: 34px;
  text-align: center;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 34px;
  z-index: 9;
  color: gray;
}

.select {
  position: relative;
}

.select p {
  position: absolute;
  font-size: 12px;
  padding-left: 14px;
  padding-top: 6px;
  color: gray;
}

.loginmodal select {
  border-bottom: 0;
  border-radius: 5px 5px 0px 0px;
}

.loginmodal input {
  border-radius: 0px 0px 5px 5px;
}

.loginmodal select,
.loginmodal input {
  height: 55px;
}

.logincheckbox input {
  height: 17px !important;
  margin-top: 3px;
}

.logincheckbox .form-check-label {
  margin-left: 5px;
}

.withlab select,
.inputs.withlab input {
  padding-top: 16px;
  font-size: 13px;
}

.loginmodal select:focus,
.loginmodal input:focus {
  box-shadow: none;
}

/* showmap */
.showmap {
  position: fixed;
  bottom: 69px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  padding: 9px;
  z-index: 999;
  width: 144px;
}

/* filter */

.filterscrol {
  /* display: grid; */
  display: flex;
  grid-template-columns: 28% 61% 10%;
  grid-gap: 1%;
  gap: 1%;
  align-items: center;
  justify-content: space-between;
}

.filterscrol div {
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: 5px;
}

.filterscrol div:first-child::after {
  content: "";
  position: absolute;
  height: 20px;
  width: 1px;
  background: #eae6e6;
  right: -10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.mCS-rounded-dark-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: red !important;
  width: 20px !important;
  height: 20px !important;
}

.filterscrol div:first-child {
  margin-right: 10px;
}

.filterscrol div:last-child {
  justify-content: end;
}

.filterscrol div:first-child {
  position: relative;
  /* height: 32px; */
}

.filterscrol p {
  border: 1px solid #d5d5d5;
  padding: 8px 10px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 0;
  display: flex;
  flex-shrink: 0;
}

.review i {
  position: relative;
}

.review span {
  position: absolute;
  content: "";
  height: 12px;
  width: 49%;
  left: 0;
  top: 0;
  color: var(--second) !important;
  overflow: hidden;
}

.owl-nav {
  height: 0;
}

.review {
  font-size: 12px;
}

.review i.fa {
  color: var(--txtgray);
}

#filterexp {
  margin-top: 80px !important;
}

#filter {
  margin-top: 78px;
}

.filter .filter-img {
  width: 30px !important;
  height: auto;
  margin: auto;
}

.filter .owl-next {
  position: absolute;
  right: 0;
  top: 20px;
  background: white;
  width: 25px;
  border-radius: 50%;
  box-shadow: 0 0 17px 28px #eaeaea9c;
  border-radius: 50% !important;
}

.filter .owl-prev {
  position: absolute;
  left: 0;
  top: 20px;
  background: white;
  width: 25px;
  border-radius: 50%;
  box-shadow: 0 0 17px 28px #eaeaea9c;
  border-radius: 50% !important;
}

.filter .item {
  text-align: center;
  border-bottom: 3px solid transparent;
}

.filter .item p {
  font-size: 12px;
  margin-top: 15px;
  color: var(--themeclr);
  margin-bottom: 0;
}

.filter .item:hover,
.filter .item.active {
  text-align: center;
  background-image: linear-gradient(358deg, #f1eff0, white);
  border-bottom: 3px solid #082e53;
  border-radius: 5px;
}

.where-card .card-img img {
  /* border-radius: 0px; */
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
  height: 270px;
  object-fit: cover;
}

.card-date-custom {
  color: #3e3e3e;
  /* font-family: Inter; */
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.d-grid.card-5 {
  grid-template-columns: 19.5% 19.5% 19.5% 19.5% 19.5%;
  gap: 10px;
  row-gap: 25px;
}

.where-cont p {
  margin-bottom: 0;
}

.filter button.owl-prev.disabled,
.filter button.owl-next.disabled {
  display: none !important;
}

.filter .owl-theme .owl-nav {
  margin-top: 0;
}

.detail-img img {
  max-height: 457px;
}

.detail-img .row img {
  height: 224px;
}

/* calendar */
.calendar .react-datepicker__month-container {
  float: unset !important;
  min-height: 100vh;
}

.calendar .react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
}

.calendar .react-datepicker__week > div {
  width: 100%;
  border: 1px solid #bbbaba;
  aspect-ratio: 1/1;
  text-align: left;
  padding: 15px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.calendar .react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

.calendar .react-datepicker__day--outside-month {
  border: none !important;
}

.react-datepicker__day--in-range.react-datepicker__day--outside-month,
.react-datepicker__day--selected.react-datepicker__day--outside-month,
.react-datepicker__day--outside-month.react-datepicker__day--in-selecting-range {
  background-color: transparent !important;
}

.calendar .react-datepicker {
  width: 100%;
  border-radius: 0px !important;
}

.availability .changeprice {
  padding: 5px 10px;
  border: 1px solid var(--themeclr1);
  background: transparent;
  border-radius: 4px;
}

.changeprice input:focus-visible {
  outline: 0px !important;
}

.stikyavail {
  position: sticky;
  top: 42px;
  height: 100%;
}

.stikybtns {
  /* position: sticky; */
  top: 0px;
  height: 75px;
  z-index: 9;
  background: #ffffff;
  width: 100%;
}

.showin1199 {
  display: none;
}

.stikybtns .btn-success {
  background: transparent !important;
  color: #000 !important;
  border: 0.2px solid rgba(62, 62, 62, 0.3);
  border-radius: 0px;
  font-size: 14px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 200px !important;
}

.stikybtns .drop-items .form-check {
  margin: 15px 0;
  width: 100%;
  overflow-x: hidden;
}

.stikybtns label img {
  height: 40px;
  width: 45px;
  border-radius: 5px;
}

.stikybtns .hostname {
  min-width: 230px;
  white-space: break-spaces;
}

.stikybtns .dropdown-menu.show {
  max-width: 300px;
  min-width: 300px;
  display: block;
  box-shadow: 0 0 5px 5px #c8c7c7;
  border-radius: 15px;
  border: 0;
}

.stikybtns .form-check {
  justify-content: start;
}

.drop-items {
  background: #fff;
  padding: 22px;

  border-radius: 15px;

  width: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  position: absolute;
  top: 70px;
  right: 0;
  transition: 0.2s;
}

.drop-down.show .drop-items {
  border-radius: 15px;
  width: 320px;
  height: 340px;
  padding: 18px;
  box-shadow: 0 0 5px 5px #dbdbdb;
}

.drop-down.show .drop-items .itemss > div.scrolablediv {
  height: 235px;
  width: 285px;
  overflow-y: auto;
}

.stikybtns .form-check input {
  flex-shrink: 0;
}

.dropbtn {
  position: relative;
}

.circlebtn {
  border-radius: 50%;
  height: 36px;
  width: 36px;
  font-size: 13px;
}

li.d-flex.reply {
  flex-direction: row-reverse;
  text-align: right;
  gap: 6px;
}

.listings .input-group {
  max-width: 300px;
}

.itemss .d-grid {
  grid-template-columns: 50% 50%;
}

.listings .input-group {
  height: 30px;
}

.listings span {
  border-radius: 30px;
}

.listings .input-group input {
  border-radius: 30px;
  height: 32px;
}

div#listing .dropbtn > button {
  height: 30px;
  padding: 3px 10px;
  border-radius: 30px;
  font-size: 14px;
}

.photos {
  display: flex;
  overflow: hidden;
  gap: 7px;
  position: relative;
}

.photos img {
  height: 150px;
  width: 100%;
  aspect-ratio: 1.5/1;
  border-radius: 5px;
}

.photos button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 150px;
  background: linear-gradient(-96deg, #ffffff, #f4f4f4e3);
  box-shadow: -20px 0px 17px 13px #f4f4f4e3;
  border: none;
  border-radius: 0;
}

.managespace .accordion-body ul li {
  margin-bottom: 10px;
}

/* footer */
.footer.offcanvas.offcanvas-bottom.show {
  max-height: 370px;
  padding-top: 10px;
}

.ul-inline-block li {
  display: inline-block;
  padding-right: 8px;
}

.ul-inline-block li a:hover {
  color: var(--themeclr);
}

.offcanvas-bottom {
  height: 50vh;
}

.fixedfooter {
  /* position: fixed; */
  bottom: 0;
  background: #f4f2f3;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  border-top: 1px solid #e0dfdf;
}

.footer {
  background: #f4f2f3;
  /* padding-top: 30px; */
}

.footer li {
  padding-bottom: 10px;
}

.footer li a {
  color: #888888;
  text-decoration: none;
  font-size: 14px;
}

.footer li a:hover {
  text-decoration: underline;
}

.footer .offcanvas-body li:first-child {
  font-weight: bolder;
  font-size: 16px;
  color: #000;
}

.footer li:first-child {
  font-weight: 500;
  font-size: 16px;
}

.footer ul {
  padding-left: 0;
}

.copy ul li {
  display: inline;
  font-size: 12px;
  padding-right: 8px;
  color: #5e5e5e;
}

.noshrink {
  flex-shrink: 0;
}

.copy ul li span {
  /* position: relative; */
  top: -4px;
}

/* / NIVITHA CSS / */
.form-check .form-check-input {
  min-width: 1rem !important;
}

@media only screen and (min-width: 1200px) {
  .row_exper_form .expcontcontainer .col-md-7 {
    padding-right: 30px !important;
  }
}

.conthead {
  color: var(--lite-black-heading);
  font-weight: 700;
  margin-bottom: 10px !important;
}

.subhead_dark {
  color: #707070;
  font-size: 12px;
  font-weight: 700;
}

.subhead_light {
  color: #9f9f9f;
  font-size: 12px;
  font-weight: 500;
}

.row_exper_form .text-muted {
  color: #9f9f9f;
  font-size: 12px;
  font-weight: 500;
}

.row_exper_form .form-check label {
  font-size: 12px;
  font-weight: 500;
  color: #6c757d !important;
  margin-top: 2px;
}

.input_grp_height_55 .input-group-text {
  height: 100%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

.text_dec_none {
  text-decoration: none;
}

.add_link {
  color: var(--orange);
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.add_link:hover {
  color: #000;
}

.input_grp_height_55 .fa-trash:hover {
  color: #082e54 !important;
}

.row_exper_form select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

.input_grp_height_55.input_trans .input-group-prepend .input-group-text {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  background-color: transparent !important;
  border-right: none !important;
}

.input_grp_height_55.input_trans input {
  border-left: none !important;
}

.form_row_col .left_label {
  min-width: 110px;
}

.form_row_col input {
  /* min-width: 264px !important; */
  width: 100% !important;
}

.form_row_col .input_trans {
  flex-wrap: nowrap !important;
}

.form_row_col .input_trans input {
  /* max-width: calc(100% - 50px) !important; */
  min-width: 100% !important;
}

.img_right_exp {
  max-height: 225px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
  /* border-radius: 10px 10px 0px 0px; */
}

.card_right_box {
  /* border-radius: 10px 10px 10px 10px; */
}

.card_right_box {
  background-color: transparent;
}

.text_blue_big {
  /* color: #082e54; */
  color: var(--orange);
  font-weight: 700;
  font-size: 16px;
}

.hr_light {
  border-color: #938b8b !important;
}

.text_blue {
  /* color: #082e54 !important; */
  color: var(--orange) !important;
}

.themescroll::-webkit-scrollbar {
  /* width: 3px; */
}

.chat-active-notification {
  width: 8px;
  height: 8px;
  background: #d53737;
  border-radius: 50%;
}

/* Track */
.themescroll::-webkit-scrollbar-track {
  /* background: #cccccc; */
}

/* Handle */
.themescroll::-webkit-scrollbar-thumb {
  /* background: #072d52; */
}

/* header anim */
.mobile-filter {
  border-bottom: 1px solid #f0f0f0 !important;
}

.inside_card_head {
  position: fixed;
  width: 50%;
  left: 0;
  margin: auto;
  right: 0;
}

.tab_header_main {
  position: absolute;
  z-index: 999;
  top: -65px;
  transition: 0.5s;
}

.tab_header_main.navpos {
  animation-name: topanime;
  animation-duration: 0.5s;
  top: 5px;
}

.owl-dots {
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  margin: auto;
}

.owl-dot {
  transform: translateX(-75px);
}

.col_card_in .inbtn {
  width: 100%;
}

.tab_header_main #topbtn.no-anim {
  animation-duration: 0s !important;
}

.tab_header_main #topbtn {
  transform: scale(1);
  animation-name: r-inputscale;
  animation-duration: 0.5s;
}

.tab_header_main.navpos #topbtn {
  transform: scale(1.2);
  animation-name: inputscale;
  animation-duration: 0.5s;
}

.inside_card_head {
  transform: scale(0.2);
  top: 0;
  z-index: -1;
  transition: 0.5s;
  opacity: 0;
}

.whereinput {
  transform: scale(1) !important;
  top: 75px;
  animation-name: whereinput;
  animation-duration: 0.5s;
  z-index: 99;
  opacity: 1;
}

.li-inline-header li button.any {
  background: transparent;
  border: none;
}

/* inbox */
.tripstage,
.msgtype {
  display: grid;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  column-gap: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.selectlist img {
  width: 45px;
  height: 40px;
  border-radius: 5px;
}

.selectlist .form-check {
  justify-content: start;
  gap: 10px;
  row-gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.tripstage .form-check,
.msgtype .form-check {
  padding-left: 0;
}

#messages .container {
  min-width: 100% !important;
  /* padding-left: 0px; */
  /* padding-right: 20px !important; */
}

.messages {
  /* height: calc(100vh - 197px); */
  max-height: 200px;
  overflow-y: auto;
}

.reserve-side {
  height: calc(100vh - 68px);
  overflow: auto;
}

.messages li > div,
.messages li a {
  display: flex;
  align-items: center;
  gap: 5px;
  /* border-bottom: 1px solid #ebebeb; */
  padding: 20px 0px;
  margin: 10px 14px;
  margin-bottom: 1px;
}

.messages li:hover {
  background: #f7f7f7;
  /* border-radius: 12px; */
}

.messages li img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.reserve-detail .profimg {
  height: 50px;
  width: 50px;
  margin-right: 7px;
}

.total {
  margin-top: 15px;
}

/* .msgcont {
 
  border-left: 1px solid #ebebeb;
} */
.withstat button {
  /* background: white; */
  padding: 6px 16px !important;
  border-radius: 0px !important;
  width: 100%;
}

.whisstatcont > button {
  background: white;
}

.withstat .cols {
  min-height: 100%;
}

.whisstatcont,
.whisstatcont button {
  height: 100%;
}

.yourwishlist button {
  display: block;
  padding-left: 0;
}

.yourwishlist button span {
  padding: 15px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  margin-right: 5px;
}

.yourwishlist button img {
  height: 46px;
  width: 46px;
  border-radius: 5px;
}

.imgs .img-1 {
  width: 100%;
}

.imgs .img-2,
.imgs .img-3 {
  height: 95px;
  display: block;
}

.imgs {
  display: flex;
  height: 190px;
  border-radius: 10px;
  overflow: hidden;
}

@keyframes inputscale {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes r-inputscale {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes topanime {
  0% {
    top: 0px !important;
  }

  100% {
    top: -65px !important;
  }
}

/* header */
.bg-white {
  background: #fff;
}

.right-head {
  position: relative;
  z-index: 9;
}

.inside_card_head {
  background-color: #fff;
}

.topcontsec.vanish {
  width: 300px;
  overflow: hidden;
  top: -56px;
  transition: 0.5s;
  z-index: -1;
}

.topcontsec {
  position: absolute;
  text-align: center;
  z-index: 99;
  /* max-width: 100%; */
  transition: 0.5s;
  top: 5px;
}

.yellownav .owl-nav .owl-next span,
.yellownav .owl-nav .owl-prev span {
  position: absolute;
  height: 30px;
  top: 45%;
  /* background: #f6cb57; */
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 60px;
  font-weight: 600;
}

.yellownav .owl-nav .owl-prev span {
  left: 8px;
}

.yellownav .owl-nav .owl-next span {
  right: 8px;
}

.owl-theme .owl-nav .disabled {
  opacity: 1 !important;
}

.owl-prev.disabled span,
.owl-next.disabled span {
  opacity: 1 !important;
}

.owl-prev.disabled span,
.owl-next.disabled span {
  opacity: 0.5;
}

.owl-dot.active span {
  background: var(--second) !important;
  width: 15px !important;
  height: 5px !important;
}

.owl-dot span {
  background: #fff !important;
  border-radius: 0 !important;
  width: 5px !important;
  height: 5px !important;
}

.yellownav:hover .owl-nav button {
  opacity: 1 !important;
}

.yellownav .owl-nav button {
  /* opacity: 0 !important; */
}

.yellownav .owl-carousel {
  position: relative;
}

.grid-30 {
  grid-template-columns: 30% 30% 30%;
}

header.header.py-1:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 0px;
  background: white;
  z-index: 8;
  transition: 0.5s;
  top: 60px;
}

header.header.py-1.topsec:after {
  height: 100px;
}

header.header.py-1:before {
  content: "";
  position: absolute;
  background: white;
  height: 0px;
  width: 100%;
  z-index: -1;
  top: 0;
  transition: 0.5s;
}

header.header.py-1.topsec:before {
  height: 60px;
}

#backdrp {
  content: "";
  position: fixed;
  width: 100%;
  top: 64px;
  height: 100vh;
  background: #7a7a7a96;
  z-index: 8;
}

.topinputs {
  position: relative;
  z-index: 999;
}

.max-850 {
  max-width: 850px;
}

header.header {
  /* position: fixed; */
  top: 0px;
  width: 100%;
  z-index: 99;
  background: white;
}

/* mmobile header */
.maps {
  display: flex;
  overflow: scroll;
  gap: 14px;
}

.maps img {
  border: 1px solid gray;
  border-radius: 10px;
}

.mobilebtn {
  border: none;
}

.where-card .item {
  position: relative;
}

.where-card .fa-heart,
.where-card .fa-heart-o {
  position: absolute;
  top: 15px;
  left: 15px;
  background: transparent;
  border: none;
  color: var(--second);
}

/* become a host */
.d-767 {
  display: none;
}

button.mobile-btn {
  background: #ffffff47;
  color: white;
  border: 1px solid white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 20px;
}

.dollinput {
  position: relative;
}

.dollinput input {
  padding-left: 25px;
}

.dollinput span {
  position: absolute;
  top: 0;
  bottom: 0;
  line-height: 3.5;
  left: 10px;
}

.tooltip .tooltip-arrow::before {
  border-right-color: transparent;
}

.tooltip-inner {
  max-width: 240px;
  padding: 15px;
  color: #020202;
  box-shadow: 0 0 5px grey;
  background-color: #fff;
  border-radius: 10px;
}

.bord button.closebtn {
  font-size: 12px;
  background: transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid gray;
}

.h-55 select,
.h-55 input,
.spaninput input,
.spaninput select {
  height: 55px;
  padding-top: 14px;
}

.btn-trans {
  background: transparent;
  border: 0;
}

button.btn.btn-outline-theme.adminblue {
  /* color: #082e54; */
  color: var(--orange);
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
  min-width: 100px;
  border-radius: 8px;
  border-color: #bbbbbb;
  max-width: 180px;
  width: 100%;
}

button.btn.btn-outline-theme.adminblue:hover {
  background-color: var(--orange);
  color: #fff;
}

img.userimg {
  height: 50px;
  width: 50px;
  aspect-ratio: 1/1;
}

.err .fa {
  background: red;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  font-size: 9px;
  line-height: 1.68;
}

input.err {
  border-color: red;
  background: #ff00000d;
}

.modal-body {
  padding-left: 30px;
  padding-right: 30px;
}

.spaninput div {
  position: absolute;
  top: 0;
  bottom: 0;
  line-height: 3.4;
  padding-left: 6px;
}

.spaninput div span:first-child {
  visibility: hidden;
}

.spaninput div span:last-child {
  position: relative;
  z-index: 999;
}

.spaninput input {
  z-index: 99;
  position: relative;
}

.spaninput {
  position: relative;
}

.modal-content .btn_sec button {
  top: unset !important;
}

.setrule hr {
  background-color: var(--bordclr);
}

.container,
.container-sm {
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}

.doublecheck .accordion-button.collapsed {
  background: transparent;
}

.doublecheck .accordion-item .form-check input {
  flex-shrink: 0;
}

.doublecheck .accordion-button {
  background: transparent;
  color: #000;
  font-size: 20px;
}

.doublecheck .accordion-header button {
  font-weight: 700;
  height: 75px;
}

.doublecheck .accordion-item {
  box-shadow: -1px 4px 7px 1px #ededed;
}

.doublecheck .accordion-item .form-check {
  justify-content: start;
}

.doublecheck .accordion-item .form-check label {
  margin-left: 15px;
}

.doublecheck .accordion-item {
  border: 1px solid #ebebeb !important;
  margin-bottom: 17px;
}

.reseve-tabs .nav-item a {
  background: none;
  /* border: 2px solid #e0e0e0; */
  /* border-radius: 30px; */
  margin-right: 15px;
  color: black;
  font-weight: 400;
  font-size: 14px;
}

.reseve-tabs .nav-item a.active {
  background: #def5fa;
  color: black;
  border-radius: 0px;
  line-height: 25px;
}

.reseve-tabs .nav-item a:hover {
  border-color: #000;
}

.no-stat-cont span {
  font-size: 30px;
  margin-bottom: 19px;
}

.modalright a {
  width: 100%;
  padding: 20px;
  font-size: 22px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  /* border: 1px solid #d6d6d6; */
  box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 8px;
  border-radius: 8px;
  text-decoration: none;
  color: #000;
}
.modalright a span {
  color: var(--themeclr1);
  font-size: 23px;
}

.setrule li button.fa {
  background: transparent;
  border: 1px solid gray;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  line-height: 0;
  display: inline-block;
  font-size: 11px;
  color: #323131;
}

button.backbtn {
  background: transparent;
  border: 0;
}

* {
  --transition: 0.15s;
  --border-radius: 5px;
  --background: #072d52;
  --box-shadow: #f8c006;
}

.cont-checkbox {
  border-radius: var(--border-radius);
  background: white;
  transition: transform var(--transition);
}

.cont-checkbox:first-of-type {
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
}

.cont-checkbox:active {
  transform: scale(0.9);
}

.cont-bg input {
  display: none;
}

.cont-bg input:checked + label {
  opacity: 1;
  box-shadow: 0 0 0 1px var(--background);
}

.cont-bg input:checked + label img {
  -webkit-filter: none;
  /* Safari 6.0 - 9.0 */
  filter: none;
}

.cont-bg input:checked + label .cover-checkbox {
  opacity: 1;
  transform: scale(1);
}

.cont-bg input:checked + label .cover-checkbox svg {
  stroke-dashoffset: 0;
}

.cont-bg label {
  display: inline-block;
  cursor: pointer;
  border-radius: var(--border-radius);
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.cont-bg label img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cont-bg label .cover-checkbox {
  position: absolute;
  right: 5px;
  top: 3px;
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* background: var(--box-shadow); */
  border: 2px solid #fff;
  transition: transform var(--transition),
    opacity calc(var(--transition) * 1.2) linear;
  opacity: 0;
  transform: scale(0);
}

.cont-bg label .cover-checkbox svg {
  width: 10px;
  height: 7px;
  display: inline-block;
  vertical-align: top;
  fill: none;
  margin: 5px 0 0 3px;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  transition: stroke-dashoffset 0.4s ease var(--transition);
  stroke-dashoffset: 16px;
}

.setrule li button:first-child {
  /* margin-right: 5px; */
}

.check-box input {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.check-box {
  position: relative;
}

.check-box img {
  width: 100%;
  pointer-events: none;
}

.no-stat {
  padding: 30px;
  /* background: #f7f7f7; */
  border-radius: 15px;
}

.no-stat-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-stat p {
  font-size: 18px;
}

.no-stat .f-14 p {
  font-size: 14px !important;
}

button.cardbtn {
  /* background: transparent;
  border: none; */
  /* height: 370px; */
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.25);
}

.not-cont {
  min-width: 250px;
  padding: 15px;
}

.large {
  /* background: black; */
  /* color: white; */
  /* border-radius: 13px; */
  /* padding: 23px;  */
}

.card-img-section {
  min-height: 360px;
  padding: 20px;
}

.cardbtn hr {
  color: #d9d9d9;
}

.card-img-section h6 {
  color: #3e3e3e;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.small {
  background-color: #222222;
  color: white;
  border-radius: 13px;
}

.large h6,
.small h6 {
  /* padding: 30px 15px; */
  text-align: center;
}

.large img,
.small img {
  max-width: 100%;
  aspect-ratio: 1/1;
  border-radius: 13px 13px 0 0;
}

.learn button {
  background: #222222;
  color: white;
  padding: 20px;
  width: 100%;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  border-radius: 15px;
}

.host-tips {
  background: black;
  padding: 43px 0;
  color: white;
}

.hosthead a.nav-link {
  position: relative;
}

.hosthead a.nav-link.active:after {
  content: "";
  position: absolute;
  height: 2px;
  /* width: 30px; */
  width: 90%;
  background: var(--themeclr);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.rightheader .dropdown > a {
  display: block;
  border-radius: 50%;
  height: 39px;
  width: 39px;
  text-align: center;
  border: 1px solid #dddddd;
  padding: 5px;
  color: #000;
}

.rightheader .dropdown > a img {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.notificationss img {
  width: 30px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px !important;
}

.rightheader .dropdown-menu {
  right: 0;
  left: auto;
}

.bell > a:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0f3";
}

.hideafter a::after {
  display: none;
}

.bell a:after {
  display: none;
}

.themedrop .dropdown-item {
  font-size: 14px;
  padding: 8px 15px;
}

.themedrop .dropdown-menu.show {
  border-radius: 15px;
}

button.gray-btn {
  /* border: 1px solid #f0f0f0;
  border-radius: 30px;
  padding: 5px 15px; */
  color: #1469e2;
  color: #1469e2;
  background: transparent;
  border: none;
  text-decoration: underline;
  font-size: 16px !important;
}

.props-head,
.props-head button {
  font-size: 12px;
}

.bord-btn img {
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  padding: 1px;
  background: #fff;
}

.bord-btn:hover {
  box-shadow: 1px 0px 15px 2px #c0c0c0;
}

.bord-btn img:first-child {
  z-index: 4;
}

.bord-btn img:nth-child(2) {
  left: -6px;
  z-index: 2;
}

.bord-btn img:last-child {
  left: -12px;
  z-index: 1;
}

.bord-btn .prof-imges {
  position: relative;
}

.bord-btn {
  padding: 7px 15px;
  border-radius: 26px;
  border: 0px solid gray;
  background: white;
  font-size: 14px;
  box-shadow: 0 0 7px 2px #c0c0c0;
}

.bord-btn img {
  width: 20px;
  height: 20px;
}

.topclose button span {
  font-size: 15px;
}

.setrule li button.disable {
  border-color: #e3e3e3;
  color: #aeaaaa;
}

.fixedbtn {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  text-align: right;
  background: #fff;
  padding: 13px 19px;
  border-top: 1px solid #d6d6d6;
}

.topclose button {
  position: absolute;
  top: 47px;
  z-index: 9;
  left: 33px;
  background: #ffffff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 0;
  padding: 0;
  box-shadow: 0 0 7px 3px #ececec;
}

.lastquest label {
  font-size: 14px;
}

.headercont {
  /* height: 115px; */
  display: flex;
  align-items: center;
  margin-top: 90px;
}

.props-head .row .col-6 {
  height: 84px;
  padding: 25px;
}

.props-head ul li {
  display: inline-block;
  margin-left: 13px;
}

.props-head ul {
  text-align: right;
}

img.propf {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #e0e0e0;
  padding: 5px;
  object-fit: cover;
}

.lastquest ul li {
  margin-bottom: 10px;
}

.lastquest .fa {
  border: none;
  background: transparent;
}

.bordered {
  border: 1px solid #c3c2c2;
  border-radius: 10px;
}

.tooltip-inner {
  text-align: left;
}

.countbtns button {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 28px;
  border: 1px solid #e6e6e6;
  color: #545454;
  font-weight: 300;
  background: #f8f8f8;
}

.countbtns input {
  border: 0.2px solid rgba(62, 62, 62, 0.3);
  border-radius: 0px;
  background: transparent;
  font-size: 23px;
  text-align: center;
  height: 60px;
}

.countbtns {
  align-items: center;
  justify-content: space-between;
}

.countbtns input::placeholder {
  font-size: 23px;
}

.hignlights ul {
  padding-left: 0;
}

.hignlights ul li {
  display: inline-block;
  padding: 10px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 0px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.15) !important;
}

.hignlights ul li:hover {
  border: 2px solid #a7aba6;
}

.hignlights ul li span {
  font-weight: 500;
}

.hignlights ul li img {
  margin-right: 10px;
  height: 24px;
}

.images .col-md-12 img {
  /* height: 290px; */
}

.images button.fullbutton {
  width: 100%;
  height: 230px;
  background: transparent;
  border: none;
  color: gray;
  padding: 0;
  border: 1px dashed gray !important;
}

.images .col-md-12 button.fullbutton {
  width: 100%;
  background: transparent;
  /* border: none; */
  color: gray;
  padding: 0;
  height: 230px;

  /* border: 1px dashed gray !important; */
}

.images button:hover {
  background: transparent;
  border: none;
  color: gray;
}

.images button:hover .uploadimage {
  border-style: solid;
}

.images .col-md-6 {
  position: relative;
}

.white-btn {
  background: white;
  color: #000;
  border-color: #fff;
  font-weight: 500;
}

.imageaction button:hover,
.imageaction button:active,
.imageaction button:focus {
  background-color: #ffffffb8;
  border-color: #ffffffb8;
}

.imageaction button {
  border-radius: 50%;
  background: #ffffffb8;
  height: 38px;
  width: 38px;
  border-color: #ffffffb8;
  color: black;
  padding: 0;
}

.imagedrop {
  width: calc(100% - 30px) !important;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
}

.imageaction button:after {
  display: none;
}

.images .row {
  row-gap: 18px;
}

.images img {
  object-fit: cover;
  width: 100%;
  height: 230px;
}

.images .uploadimage {
  height: 230px;
  position: absolute;
  top: 0;
  width: 100%;
}

.dashedbord {
  /* border: 1px dashed #c1c1c1; */
  padding: 40px;
}

.dragphotos span.fa.fa-picture-o {
  font-size: 43px;
  margin-bottom: 16px;
}

button.currentloc {
  background: transparent;
  border: none;
  margin-bottom: 20px;
}

button.currentloc .fa {
  background: #ededed;
  padding: 10px;
  margin-right: 8px;
  border-radius: 50%;
}

.address {
  background: white;
  width: calc(100% - 60px);
  margin: auto;
  height: 140px;
  border-radius: 0 0 15px 15px;
  padding: 15px;
  position: absolute;
  left: 0;
  right: 0;
}

.input-group {
  height: 55px;
}

.themeinput .input-group input {
  border-radius: 0 30px 30px 0;
  border-left: 0;
}

.hostexp .welcome-back {
  color: var(--lite-black-heading);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.hostexp .heading-des {
  color: var(--lite-black-sub-heading);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.hostexp p {
  color: var(--lite-black-sub-heading) !important;
  font-size: 19px !important;
}

.hostexp .show-what {
  color: var(--orange) !important;
}

.story-heading {
  color: var(--orange);
  font-weight: 700;
}

.show-what-des {
  font-size: 22px !important;
}

.story-more-btn {
  color: var(--orange);
  cursor: pointer;
}

/* .hostexp .btn-theme-create {
  background-color: var(--blue) !important;
  border: none !important;
  outline: none !important;
} */
.hostexp .experience-title {
  color: var(--orange) !important;
}

.themeinput .input-group .input-group-text {
  border-radius: 30px 0 0 30px;
  border-right: 0;
  background: #fff;
  padding: 0 22px;
  font-size: 20px;
}

.amenities {
  /* margin-bottom: 30px; */
}

.amenities > button {
  border: 2px solid transparent;
  padding: 15px;
  background: transparent;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  min-height: 191px;
}

.amenities.active > button,
.amenities > button:hover {
  border: 2px solid #a7aba6;
}

.amenities img {
  /* margin-top: 7px; */
  /* margin-bottom: 10px; */
  height: 24px;
}

.amenities {
  /* height: 150px; */
  /* margin-bottom: 15px; */
  text-align: center;
}

.hignlights ul li:hover {
  cursor: pointer !important;
}

input {
  background-image: none;
}

.rightcont .bg-white {
  padding: 22px;
  border-radius: 10px;
}

.inputs select {
  font-size: 14px;
}

.groupform .inputs input,
.groupform .inputs select {
  height: 55px;
  border-top: 0;
  border-radius: 0;
}

.groupform .inputs:first-child {
  border-radius: 10px 10px 0 0;
  border-top: 1px solid #ced4da;
}

.groupform .inputs:first-child input {
  border-radius: 10px 10px 0 0;
}

.groupform .inputs.lastchild,
.groupform div.inputs.lastchild select {
  border-radius: 0 0 10px 10px !important;
}

.inputs p {
  position: absolute;
}

.inputs select {
  height: 55px;
}

.bg-light-gray {
  background: #f2f4f6;
}

.propstype .nav-item a {
  display: flex;
  align-items: baseline;
}

.propstype .nav-item a {
  color: gray;
}

.propstype .nav-item a.active {
  background: transparent;
  color: var(--themeclr);
}

.propstype .nav-item a.completed span {
  color: green;
}

.qustheight {
  /* height: calc(100vh); */
  /* height: calc(100vh - 115px); */
}

.headercont.left {
  position: fixed;
}

.place-questions p {
  color: #fff;
}

.host-img-sec {
  width: 350px;
  height: 350px;
  object-fit: contain;
}

.host-img-sec img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.rightcont {
  height: 406px;
  overflow: auto;
}

.place-questions {
  display: flex;
  /* height: 100vh; */
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  color: white;
  height: 400px;
  /* margin-top: 116px; */
  margin-top: 160px;
}

.placecards {
  background: transparent;
}

.placecards img {
  border-radius: 5px;
  max-width: 100px;
  height: 100px;
  width: 100%;
}

.fixeddiv:before {
  content: "";
  background: var(--themeclr);
  position: absolute;
  width: 6.666%;
  height: 2px;
  top: 0;
  left: 0;
  transition: 0.2s;
}

.propstype .host-text-name {
  color: var(--lite-black-heading);
}

.propstype .more-btn {
  color: var(--lite-black-sub-heading);
  text-decoration: underline;
}

div#progressdiv[data-counter="1"]:before {
  width: 13.332%;
}

div#progressdiv[data-counter="2"]:before {
  width: 19.999%;
}

div#progressdiv[data-counter="3"]:before {
  width: 26.664%;
}

div#progressdiv[data-counter="4"]:before {
  width: 33.33%;
}

div#progressdiv[data-counter="5"]:before {
  width: 39.997%;
}

div#progressdiv[data-counter="6"]:before {
  width: 46.662%;
}

div#progressdiv[data-counter="7"]:before {
  width: 53.329%;
}

div#progressdiv[data-counter="8"]:before {
  width: 59.994%;
}

div#progressdiv[data-counter="9"]:before {
  width: 66.66%;
}

div#progressdiv[data-counter="10"]:before {
  width: 73.327%;
}

div#progressdiv[data-counter="11"]:before {
  width: 79.992%;
}

div#progressdiv[data-counter="12"]:before {
  width: 86.659%;
}

div#progressdiv[data-counter="13"]:before {
  width: 93.324%;
}

div#progressdiv[data-counter="14"]:before {
  width: 99.99%;
}

.map-custom-position {
  top: 100px;
}

#proptype .mapComponent {
  /* height: calc(100vh - 55px); */
  /* width: 100vw; */
  /* position: absolute; */
  /* width: 50%; */
  padding: 0;
  /* margin-top: 0; */
  /* margin-top: 117px; */
  height: 406px;
}

#proptype .mapComponent.map_height_loc_map {
  max-height: calc(100vh - 270px);
}

.placetype_heoght_fix.placetype {
  max-height: calc(100vh - 320px);
  min-height: unset;
}

.headercont button {
  z-index: 9;
  position: relative;
}

.fixeddiv:after {
  content: "";
  background: #ddddddfa;
  position: absolute;
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  z-index: -1;
}

.placecards {
  /* display: flex; */
  width: 100%;
  /* border-radius: 10px; */
  padding: 15px;
  justify-content: space-between;
  /* border: 1px solid #c7c7c7; */
  margin-bottom: 10px;
  border: 2px solid transparent;
  border: none;
  background: #fff;
  box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.15);
  /* width: 291px; */
  /* height: 257px; */
}

.placecards:hover {
  border: 2px solid #a7aba6;
}

.placetype ul li:last-child .placecards {
  /* margin-bottom: 18px; */
}

.scroll > div:nth-child(2) {
  display: none;
}

.rightcont h5 {
  font-weight: 600;
}

.scroll > div:last-child {
  visibility: hidden;
}

.scroll > div {
  overflow-x: hidden !important;
}

.place-questions p {
  font-size: 24px;
  line-height: 1.3;
  font-weight: 600;
}

.placecards.active {
  border: 2px solid #a7aba6;
}

.placecards p {
  margin-bottom: 0;
}

.describeplace li button {
  text-align: left;
}

/* .placetype {
  text-align: center;
}
.placecards {
  position: relative;
  border-radius: 12px;
  border:  2px solid transparent;
}
.placecards.active {
  border: 2px solid #eed78b;
  background: #eed78b;
}
.placecards:hover {
  border: 2px solid #eed78b;
  background: #eed78b;
} */
.fixeddiv {
  /* position: fixed; */
  bottom: 0;
  /* width: 50%; */
  background: white;
  height: 55px;
  display: flex;
  justify-content: space-between;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  align-items: center;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.fixeddiv button {
  height: 40px;
}

.enable.btn.btn-light {
  height: 50px;
  background: #ec6043;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0px;
  position: relative;
  z-index: 10;
}

.enable.btn.btn-light:hover {
  background-color: var(--lite-black-sub-heading) !important;
}

.disable.btn.btn-light {
  cursor: not-allowed;
}

.propstype .back-btn {
  width: 191px;
  height: 48px;
  background: #e9e9e9;
  color: #999999;
  text-decoration: none;
  border-radius: 0px;
  position: relative;
  z-index: 10;
}

.propstype .back-btn:hover {
  background: #ec6043;
  color: #fff;
}

.propstype .next-btn {
  width: 191px;
  height: 48px;
  background-color: var(--blue);
  color: white;
}

/* .headercont{
  position: fixed;
  right: 0;
  width: 50%;
} */
.reserve .nav.nav-pills {
  overflow: auto;
  flex-wrap: nowrap;
  background: #fafafa;
  padding: 5px;
}

.reseve-tabs .nav-item a {
  height: 42px;
  white-space: nowrap;
}

.showallimg .item img {
  height: auto;
  max-width: 346px;
  margin: auto;
}

.showallimg button.owl-next {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 31px;
  margin: auto !important;
  background: var(--themeclr) !important;
  width: 31px;
  color: #fff !important;
}

.showallimg button.owl-prev {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 31px;
  margin: auto !important;
  background: var(--themeclr) !important;
  width: 31px;
  color: #fff !important;
}

.showallimg {
  background: #fff;
  font-size: 14px;
  position: absolute;
  right: 0;
  bottom: 15px;
  padding: 5px;
  border-radius: 5px;
}

.detail_images {
  position: relative;
}

.mobileresevebtn {
  display: none;
}

.dates input {
  font-size: 14px;
  padding-left: 0px;
}

.showin767 {
  display: none;
}

.calendar .react-datepicker__week > div p {
  line-height: 0;
  color: gray;
}

.calendar .react-datepicker__header {
  position: sticky;
  top: 70px;
  border-top: 1px solid #ced4da;
}

.todate .react-datepicker__header {
  position: unset;
}

.todate input {
  border: 1px solid #80808082;
}

.upphoto button {
  position: relative;
  border: 1px dashed gray;
  min-height: 145px;
  flex: 1;
  aspect-ratio: 1.5/1;
}

.calendar .react-datepicker__week > div {
  cursor: pointer;
}

.calendar .react-datepicker__week > div:hover {
  border-radius: 0px !important;
  color: #000 !important;
}

.upphoto button span {
  font-size: 50px;
  color: gray;
}

.upphoto button p {
  color: gray;
  font-size: 14px;
}

.upphoto button input {
  position: absolute;
  height: 100%;
  width: 100%;
  background: red;
  top: 0;
  left: 0;
  opacity: 0;
}

.expdetails .experience_calendar .react-datepicker__header {
  background: transparent !important;
}

.expdetails .experience_calendar .react-datepicker__header,
.expdetails .experience_calendar .react-datepicker {
  border: 0;
}

.expdetails .experience_calendar .react-datepicker__week,
.expdetails .experience_calendar .react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
}

.navpos select#countrycode:hover,
.navpos .col_card_in:hover .dropdown button select#countrycode {
  background: transparent;
  color: #fff;
}

.navpos .col_card_in:hover .dropdown button select#countrycode option {
  color: #000;
  padding-left: 10px !important;
}

.navpos .react-datepicker__header {
  background: transparent;
  border-bottom: 0;
}

.navpos .react-datepicker {
  border: 0px solid #aeaeae;
}

.googlelogin button {
  border: 1px solid #4c69ba !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  font-size: 12.5px;
  font-weight: 600;
}

.googlelogin button div {
  font-size: 14.5px;
  font-weight: 600;
}

.googlelogin button svg {
  filter: brightness(0);
}

.googlelogin button {
  width: 200px !important;
  height: 41px !important;
  /* margin-top: 12px; */
}

@media (max-width: 1199px) {
  .expsidebar {
    width: 200px !important;
  }

  .expcont {
    width: calc(100% - 200px) !important;
  }

  .mobile {
    padding: 9px !important;
  }

  .mob-box:before,
  .mob-box:after {
    display: none;
  }

  .showin1199 {
    display: block;
  }

  .dates input {
    font-size: 12px;
    /* width: 82%; */
    width: 100%;
    padding: 0.3rem 0px !important;
  }

  .filterscrol {
    grid-template-columns: 35% 53% 12%;
  }

  .d-grid.card-5 {
    grid-template-columns: 33% 33% 33%;
  }
}

@media (max-width: 1025px) {
  /* .rightcont_don_mob {
    display: none !important;
  } */

  .expdetails .footer-section {
    /* padding-bottom: 100px !important; */
  }

  .mobileresevebtn {
    display: block;
  }

  .exp-details_reserve_pop {
    display: none !important;
  }

  .shadowbox.stikybox {
    padding: 10px !important;
    /* box-shadow: none !important; */
    margin-bottom: 0px !important;
    margin-top: 20px;
  }

  .pricepernight .user-logo {
    width: 60px !important;
    height: 60px !important;
  }

  .stikybox {
    height: auto;
  }

  .leftcont.col-md-8 {
    flex: 0 0 auto;
    width: 100%;
  }

  .col_4_tiv.col-md-4 {
    flex: 0 0 auto;
    width: 100%;
  }

  .rightcont.col-md-4 {
    display: none;
  }

  .mobileresevebtn {
    position: fixed;
    bottom: 0 !important;
    background: white;
    width: 100%;
    text-align: right;
    padding: 15px;
    box-shadow: 0 0 8px 4px #d6d6d6;
    z-index: 9;
  }

  .request-to-book-btn button {
    width: unset !important;
    padding: 10px 50px !important;
  }

  .request-to-book-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom_overview_hr {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}

.mobilereseve {
  border-top: 0.5px solid #d6d6d6;
  border-bottom: 0.5px solid #d6d6d6;
}

.graybox {
  background: #f7f7f7;
  border-radius: 30px;
  padding: 10px;
}

.noresize {
  resize: none;
}

.msgsec {
  height: calc(100vh - 130px);
  /* background: red; */
  overflow-x: auto;
}

@media (max-width: 991px) {
  .card-filter-sec-custom {
    /* height: 100% !important; */
  }

  .resortimg img {
    height: 260px !important;
  }

  .msg {
    height: calc(100vh - 295px);
  }

  .mobilereseve {
    display: block;
  }

  .reserve-side {
    position: absolute;
    background: #fff;
    left: 100%;
    transition: 0.5s;
    width: 100%;
  }

  /* 
  .pagetop {
    padding-top: 15px !important;
  } */
  .detail_content img.desc_icon {
    max-width: 30px;
  }

  .detail_ful {
    margin-top: 60px;
  }

  .card_nav ul {
    margin-top: 20px !important;
    margin-bottom: 14px !important;
  }

  .expdetails .footer,
  #contacthost .footer {
    margin-bottom: 70px;
  }

  p.reviews_text {
    font-size: 14px;
  }

  .img_full_height {
    max-height: 255px !important;
    min-height: 255px !important;
  }

  .progress_review {
    width: 116px !important;
  }

  .headercont li button .prof-imges {
    display: none;
  }

  .mt-78 {
    margin-top: 30px;
  }

  .mt_new_aho_a {
    margin-top: 90px !important;
  }

  .btn_blue {
    height: 30px;
    width: 30px;
    font-size: 12px;
  }

  #filterexp {
    margin-top: 20px !important;
  }

  .mobile-filter {
    padding-top: 56px;
  }

  #filter {
    margin-top: 27px;
  }

  .prof img {
    width: 35px;
    height: 35px;
    margin-left: 7px;
  }

  .lang {
    display: none;
  }

  .tab_header_main {
    position: unset;
  }

  .inside_card_head {
    position: unset !important;
    width: 100% !important;
  }

  .d-grid.card-5 {
    grid-template-columns: 32.5% 32.5% 32.5%;
  }

  .filterscrol {
    grid-template-columns: 45% 50% 5%;
  }

  .btn.filterbtn span {
    display: none;
  }

  .filterbtn {
    min-width: 33px;
    text-align: center;
    padding: 5px 5px;
    font-size: 10px;
    height: 33px;
  }
}

.typemsg textarea {
  /* height: 44px; */
  /* border-radius: 18px; */
  /* padding: 12px 0px 0px 27px;
  position: absolute;
  top: 5px;
  display: flex;
  align-items: center;
  height: 50px; */
  height: 50px;
  padding: 12px 0px 0px 27px;
}

.typemsg {
  margin-bottom: 10px;
  position: relative;
}

.typemsg button {
  position: absolute;
  right: 4px;
  background: #ec6043;
  color: #fff;
  width: 130px;
  /* border-radius: 50%; */
  height: 39px;
  border: 0;
}

.typemsg button:hover {
  background: #1b1b1b;
}

.steps button span {
  font-size: 14px;
}

.chat-rev-message {
  /* width: 83px;
  height: 34px; */
  max-width: 60%;
  margin-left: auto;
  padding: 10px 20px;
  font-size: 14px;
  flex-shrink: 0;
  background-color: #fefefe;
  border-radius: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
}

.chat-received-message {
  /* width: 83px;
  height: 34px; */
  flex-shrink: 0;
  padding: 10px 20px;
  font-size: 14px;
  background-color: #cbcbcb;
  color: #000 !important;
  border-radius: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
}

/* .chat-custom-bg {
  background: #ebeff2;
} */
.steps button span:first-child {
  background: #ec6043;
  color: #fff;
  margin-right: 8px;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  line-height: 2.3;
}

.steps button {
  background: transparent;
  border-color: transparent;
}

.countbtns > * {
  flex-shrink: 0;
}

.countbtns input {
  margin: 0 3px;
}

.aboutuser ul li {
  margin-bottom: 10px;
}

.messages .chat-name {
  color: #3e3e3e;
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.messages .chat-description {
  color: #000;
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  margin-top: 5px;
  line-height: normal;
}

.searchbtn {
  height: 30px;
}

.searchbtn span {
  background: transparent;
  border-radius: 0px;
  /* border-right: 0px !important; */
}

.searchbtn input {
  border-radius: 0px;
  border-left: 0;
}

/* ...........experiance............*/
.expsidebar {
  width: 230px;
  position: fixed;
  top: 0;
  z-index: 9999;
  background: #ebeff2;
}

.expsidebar .sidebar-lists {
  height: calc(100vh - 74px);
  overflow-y: auto;
}

.expcont {
  width: calc(100% - 230px);
  margin-left: auto;
  min-height: 100vh;
}

.li-head button {
  padding: 16px 10px;
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  color: var(--lite-black-heading);
  font-size: 14px;
  font-weight: 900;
  background-color: transparent !important;
}

.expsidebar button:hover {
  /* background: var(--white) !important;
  color: var(--lite-black-heading) !important; */
  border-radius: 0px !important;
}

.expsidebar button {
  color: var(--lite-black-heading) !important;
}

.mob-box {
  position: relative;
  max-width: 400px;
  margin: auto;
}

.mob-box:after,
.mob-box:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* border-radius: 10px; */
  top: 0px;
  /* box-shadow: 0 0 5px #d9d9d9; */
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.25);
  background: #fff;
}

.mob-box:after {
  z-index: -2;
  left: -13px;
  transform: rotate(-2deg);
}

.mob-box:before {
  z-index: -1;
  left: -0px;
  transform: rotate(8deg);
}

.mobile {
  background: #fff;
  max-width: 400px;
  margin: 53px auto;
  box-shadow: 0 0 5px #d9d9d9;
  /* border-radius: 10px; */
  padding: 17px;
  height: 530px;
}

.mobile > div {
  height: 500px;
  overflow-y: auto;
  /* border-radius: 11px; */
  /* box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px !important; */
  /* box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.25);  */
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.25);
}

.hostexp img {
  max-width: 100%;
}

.explists img {
  max-width: 100%;
  aspect-ratio: 1/1;
  width: 100%;
}

.editexp {
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  flex-wrap: nowrap;
  display: flex !important;
  width: calc(100% - 12px);
  overflow: auto;
  padding: 12px 10px;
}

.modal-content {
  background-color: var(--white) !important;
  border-radius: 0px !important;
}

.editexp > .shadowbox {
  min-width: 300px;
  margin-bottom: 26px;
}

.shadowbox h5 {
  font-size: 23px;
  font-weight: 500;
}

.shadowbox .horizontal-line {
  width: 100%;
  height: 3px;
  background-color: #d9d9d9;
  margin: 0px 30px;
}

.expmap span {
  /* height: 40px;
  display: block;
  width: 40px;
  background: #cfcfcf;
  text-align: center;
  line-height: 2.5;
  border-radius: 50%;
  margin-bottom: 12px; */
  font-size: 35px;
  font-weight: 700;
}

.sidebar-lists .dropdown-menu {
  background-color: #ebeff2;
  position: relative !important;
  transform: translate(0px, 0px) !important;
}

.sidebar-lists .dropdown-menu a {
  color: var(--lite-black-heading);
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  padding-top: 8px;
  cursor: pointer;
}

.sidebar-lists .dropdown-menu a span {
  background-color: #ccc;
  border-radius: 50%;
  padding: 3px;
  font-size: 10px;
}

.li-head .btn-success:focus {
  background: transparent !important;
  color: var(--orange) !important;
  box-shadow: none !important;
}

.sidebar-lists .dropdown-menu a:hover {
  background: var(--white) !important;
  color: var(--lite-black-heading);
}

.sidebar-lists .dropdown-menu a:hover span {
  color: #457baf;
}

.sidebar-lists .dropdown-menu a:focus {
  color: var(--orange);
}

.sidebar-lists .dropdown-menu {
  display: block !important;
}

.expcontcontainer {
  min-height: 500px;
}

.sidebar-lists .dropdown-item.active,
.sidebar-lists .dropdown-item:active {
  color: var(--orange);
  background-color: transparent;
}

.expcontcontainer ul li {
  margin-bottom: 5px;
  font-size: 14px;
}

.expcontcontainer .form-control {
  font-size: 14px;
  color: gray;
  border-radius: 0px !important;
}

.selectimg {
  position: relative;
  border: 1px dashed gray;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectimg img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.selectimg input {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}

.container-theme {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.chat-active-bg {
  background: #f5f6f8;
}

@media (max-width: 767px) {
  .rightheader.mob_rigt_je {
    margin-top: 20px;
  }

  a.navbar-brand img,
  .logo img {
    max-width: 120px !important;
    max-height: 46px !important;
  }

  #messages .container {
    /* padding-left: 20px; */
  }

  .expcontcontainer {
    min-height: unset !important;
  }

  .expcont {
    width: 100% !important;
    min-height: unset !important;
    margin-top: 65px;
    margin-bottom: 40px;
  }

  .expsidebar {
    width: 100% !important;
  }

  .expsidebar .sidebar-lists.mobildesidebar {
    height: 0 !important;
    min-height: unset !important;
  }

  .expsidebar .sidebar-lists {
    min-height: 100vh;
    /* overflow: hidden; */
  }

  .explists img {
    max-width: 140px;
  }

  .calendar .react-datepicker__week > div {
    gap: 0px;
    margin: 1px;
  }

  .showinthis767 {
    display: block !important;
  }

  /* .msgcont.mobilecont {
    right: 3px;
    transition: 0.5s;
    width: 100%;
    top: 0px;
  }

  .msgcont {
    position: absolute;
    transition: 0.5s;
    right: -100%;
    background: #ebeff2;
  } */

  .detail_heading {
    font-size: 25px !important;
  }

  .cancelpolicy .d-flex p {
    font-size: 14px;
  }

  .showin767 {
    display: block !important;
  }

  .detail_images .col-12 {
    /* display: none; */
  }

  .lists p.desc,
  .lists button {
    display: none;
  }

  .lists {
    box-shadow: 0px 6px 16px rgb(0 0 0 / 12%) !important;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
  }

  .rightheader .dropdown-menu {
    right: auto;
    left: 0;
  }

  .mobilehead.offcanvas-body a {
    background: #f2f2f2;
    padding-left: 10px !important;
    margin-bottom: 5px;
    color: black;
  }

  .mobilehead.offcanvas-body .themedrop a {
    padding-left: 5px !important;
  }

  .mobilehead .dropdown-menu.show a {
    background: white;
  }

  .mobilehead .dropdown-menu.show {
    margin-bottom: 15px;
  }

  #proptype .mapComponent {
    width: 100%;
    margin-top: 0px;
  }

  .d-767 {
    display: block;
  }

  .headercont.left {
    width: calc(100vw - 25px);
    display: flex;
    justify-content: space-between;
  }

  .place-questions p {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #proptype .fixeddiv {
    width: 100%;
  }

  .row.justify-content-center.qustheight {
    height: unset !important;
    margin-bottom: 18px;
  }

  .headercont.left {
    height: 60px;
    display: inline-flex;
  }

  .headercont {
    display: block;
  }

  .mapComponent {
    height: 250px;
    margin-top: 10px;
  }

  .search.mt-78 {
    margin-top: 20px;
  }

  .grid_calendar_div {
    display: block !important;
  }

  .filterscrol {
    justify-content: space-between;
  }

  .filterscrol div {
    justify-content: unset;
    gap: 10px;
  }

  .d-grid.card-5 {
    grid-template-columns: 49% 49%;
    gap: 2%;
  }

  .d-none-767 {
    display: none !important;
  }

  .filterscrol {
    grid-template-columns: 85% 15%;
  }
}

@media (min-width: 767px) {
  .expdetails .experience_calendar {
    margin-top: 20px;
  }

  .expdetails .experience_calendar .react-datepicker__week,
  .expdetails .experience_calendar .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
  }

  .expdetails .experience_calendar .react-datepicker__week > div {
    aspect-ratio: 1/1;
    width: 100%;
    /* line-height: 4; */
  }

  .expdetails .experience_calendar .react-datepicker__day-name {
    width: 100%;
  }

  .expdetails .experience_calendar .react-datepicker > div {
    width: 50%;
  }

  .expdetails .experience_calendar .react-datepicker {
    width: 100%;
    border: 0;
  }

  .expdetails .experience_calendar .react-datepicker__month-container {
    margin-right: 15px;
  }

  .expdetails .experience_calendar .react-datepicker > div {
    width: calc(50% - 15px) !important;
  }

  .expdetails .experience_calendar .react-datepicker__current-month {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .map_div_web.d-none {
    display: block !important;
    width: 100%;
    height: 100%;
  }

  .button_div_map {
    display: none !important;
  }

  .max-width-50 {
    max-width: 50%;
  }

  .dd_menu_shadow_card {
    transform: translate(0px, 68px) !important;
  }

  .expdetails
    .experience_calendar
    .react-datepicker
    .react-datepicker__month-container {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .no_border_picker .react-datepicker {
    border: none !important;
  }

  .filter_date_picker_cutom .react-datepicker__navigation-icon--previous {
    right: -5px;
  }

  .filter_date_picker_cutom .react-datepicker__navigation--previous {
    left: -11px;
  }

  .subscribe_flex {
    display: block !important;
  }

  .date_sign_fon {
    font-size: 16px !important;
  }

  .expsteps_repo {
    min-width: 100% !important;
  }

  .themedrop.bell .dropdown-menu {
    max-width: 280px !important;
  }

  .place-questions {
    height: unset !important;
    min-height: 400px !important;
  }

  .placetype.placetype_hei_new {
    min-height: 227px;
  }

  .expcont .conthead {
    font-size: 20px !important;
  }

  .border_box_left_p {
    flex-direction: column !important;
  }

  .flex_whre_col {
    flex-direction: column !important;
  }

  .flex_whre_col .btn-theme {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .block_mob_sel_new {
    display: block !important;
  }

  .block_mob_sel_new .block_mob_sel_new_padin {
    padding-left: 0px !important;
  }

  .mobi_fle_ger .d-flex.jc-between {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .youtr_tp_co.yourtrip .d-flex {
    flex-direction: column;
    align-items: flex-start;
  }

  .flrx_mob_co {
    flex-direction: column !important;
  }

  .flrx_mob_co img {
    margin-right: 0px !important;
  }

  .flrx_mob_co .ps-3 {
    padding-left: 0px !important;
    margin-top: 20px !important;
    text-align: center;
  }

  .resortimg img {
    height: 200px !important;
  }

  .disputechat-lists textarea {
    height: 75px !important;
  }

  .disputechat-lists .chatprof {
    width: 35px !important;
    height: 35px !important;
  }

  .disputechat-lists ul {
    padding-left: 0;
  }

  .successpay p {
    font-size: 25px !important;
  }

  .yourtrip p {
    font-size: 12px;
  }

  .form_row_col .input_trans input {
    min-width: unset !important;
  }

  .expcontcontainer .max-width-100 {
    max-width: 35px;
  }

  .expdetails .experience_calendar .react-datepicker__week {
    font-size: 12px;
  }

  .expdetails
    .experience_calendar
    .react-datepicker
    .react-datepicker__month-container {
    width: 100%;
  }

  .expdetails .experience_calendar .react-datepicker .react-datepicker__header {
    margin-top: 15px;
  }

  .stikybtns .drop-down.show .drop-items {
    right: 10px;
  }
  .drop-down.show .drop-items {
    width: 295px;
  }

  .hosthead button {
    border: none;
    padding: 0;
  }

  .dropbtn {
    position: unset;
  }

  .calendar .react-datepicker__month-container {
    min-height: unset;
  }

  .calendar .react-datepicker__week > div {
    text-align: center;
    padding: 0px 3px;
    font-size: 10px;
  }

  a.navbar-brand img,
  .logo img {
    max-width: 100%;
  }

  .imgs {
    height: 225px;
  }

  .imgs .img-2,
  .imgs .img-3 {
    height: 113px;
  }

  .imgs img {
    object-fit: cover;
  }

  .progress_review {
    width: 140px !important;
  }

  .insight-tab .owl-nav {
    position: unset;
  }

  .minus_div,
  .plus_div {
    width: 25px !important;
    height: 25px !important;
    font-size: 10px !important;
  }

  .guest-custom-detail .minus_div,
  .guest-custom-detail .plus_div {
    width: 18px !important;
    height: 18px !important;
  }

  .guest-custom-detail .plus_div .fa {
    top: 0px !important;
  }

  .placecards img {
    max-width: 45px;
    height: 45px;
  }

  .rightcont h4,
  .rightcont h5,
  .rightcont h3 {
    font-size: 18px;
  }

  .guestcount h5 {
    font-size: 16px !important;
  }

  .countbtns input {
    max-width: 185px !important;
  }

  .rightcont p,
  .rightcont label {
    font-size: 14px;
  }

  .place-questions p {
    font-size: 20px;
  }

  .countbtns button {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }

  /* .amenities {
    height: 100px;
  } */
  .amenities > button {
    padding: 3px 2px;
  }

  .amenities p {
    font-size: 12px;
  }

  .d-grid.card-5 {
    grid-template-columns: 100%;
    gap: 0;
    margin-bottom: 15px;
  }

  /* .user-icon-img {
    width: 70px !important;
    height: 70px !important;
  } */
}

@media (min-width: 575px) {
  .headercont {
    padding: 0 33px;
  }

  .who_sub .d-flex {
    justify-content: end;
  }

  div#proptype .fixeddiv {
    height: 80px;
  }
}

@media (max-width: 425px) {
  .explists img {
    max-width: 110px;
  }

  .explists h4 {
    font-size: 14px;
  }
}

/* comon */
@media only screen and (min-width: 1300px) {
  .container.container_custom {
    /* max-width: 90%; */
    max-width: 96% !important;
  }
}

@media (max-width: 1199px) {
  .searchreview .w-50 {
    width: 100% !important;
  }

  #dropdown-basic .fa-bars {
    /* display: none; */
  }

  .inside_card_head {
    position: fixed;
    width: 60%;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .container.container_custom {
    max-width: 960px;
  }
}

/* / DETAIL PAGE CSS / */
.thingstoknow img {
  width: 20px;
  margin-right: 7px;
  height: 20px;
}

.stikybox {
  position: sticky;
  top: 85px;
  /* height: 650px; */
  margin-bottom: 30px;

  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.25) !important;
}

.selectdate {
  /* border: 1px solid #ced4da;
  border-radius: 0px; */
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.25) !important;
  /* padding: 0px 15px; */
}

.cancelbox.cancelbox_witho_border {
  border: none !important;
}

.selectdate.selct_ne .accordion-item .accordion-collapse {
  width: calc(100% + 2px);
  margin-left: -1px;
}

.selct_ne .accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.selectdate .dates label {
  position: absolute;
  font-size: 14px;
  /* left: 10px;
  top: 3px; */
  left: 0px;
  top: 0px;
  color: var(--themeclr);
  font-weight: 500;
}

.placeoffers ul li {
  border-bottom: 1px solid #dcdcdc;
  padding: 17px 0;
}

.selectdate .dates input:first-child {
  border-right: 1px solid #ced4da;
}

.selectdate .dates input {
  border: none;
  border-radius: 0;
  background: transparent;
  font-weight: 500;
  font-size: 18px;
}

.selectdate .accordion {
  border: none;
  /* border-top: 1px solid #ced4da; */
  border-radius: 0;
  background: transparent;
}

.selectdate .accordion-item {
  position: relative;
  border: none;
}

.selectdate .accordion-item .accordion-collapse {
  position: absolute;
  background: white;
  width: 100%;
  /* border: 1px solid rgb(206, 212, 218); */
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.25);
  z-index: 9;
}

.selectdate .accordion-header button {
  background: transparent;
}

.selectdate .accordion-header button:focus {
  box-shadow: none;
}

.flutter-pads-title {
  color: #ec6043;
}

.login .header-title {
  font-size: 18px;
  font-weight: 400;
  color: #3e3e3e;
}

.detail_ful {
  margin-top: 110px;
}

.detail_heading {
  /* color: var(--themeclr); */
  /* color: #f5593e; */
  color: var(--black);
  font-size: 30px;
  font-weight: 700;
}

.detail_desc {
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 600;
}

.fa-star.full_star {
  color: var(--orange);
  font-size: 12px;
}

.detail_desc a {
  color: #1a5a9c;
}

.detail_desc a:hover {
  color: #1d3956;
}

.img_full_height {
  min-height: 500px;
  max-height: 500px;
  width: 100%;
  border-radius: 12.5px;
  object-fit: cover;
}

.img_half_height {
  min-height: 246px;
  max-height: 246px;
  object-fit: cover;
  width: 100%;
  border-radius: 12.5px;
}

.prof_img_round {
  max-width: 56px;
  min-width: 56px;
  border-radius: 50%;
  border: 1px solid #ccc;
  object-fit: cover;
}

.desc_icon {
  max-width: 45px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.detail_desc_grey {
  /* color: var(--txtgray); */
  color: var(--black);
  font-size: 14px;
}

.link_blue_border {
  color: var(--orange);
  font-size: 12px;
  font-weight: 500;
}

.link_blue_border:hover {
  color: #000;
}

.card_sleep {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.25);

  min-height: 151px;
}
.card_sleep .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card_sleep .name {
  font-size: 22px;
  font-weight: 600;
}

.place_icon {
  max-width: 24px;
  max-height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.experience_calendar .btn_calc:first-child {
  top: 0px !important;
  left: 0px !important;
}

.experience_calendar .btn_calc:last-child {
  top: 0px !important;
  right: 0px !important;
}

.pricepernight img {
  height: 100px;
  width: 100px;
  border-radius: 7px;
  aspect-ratio: 1/1;
}

.experience_calendar .grid_calendar_div {
  grid-template-columns: repeat(2, calc(50% - 32px)) !important;
}

.f-20 {
  font-size: 20px;
}

.progress_review {
  width: 200px;
  height: 5px !important;
  border-radius: 10px !important;
  background-color: #c0c2c1 !important;
  display: inline-flex;
}

.progress_review .progress-bar {
  background-color: var(--orange);
}

.map_detail {
  /* min-height: 480px; */
  min-height: 600px;
  height: 600px;
}

.custom_google_map > div > div {
  border-radius: 15px;
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.3);
}

.mapppfff {
  display: none;
}

/* .detail_images .col-12
{
  padding-left: 5px !important;
  padding-right: 5px !important;
} */
/* / END DETAIL PAGE CSS / */

/* ........a............ */
.loginsign .nav-item a.active {
  /* background: var(--themeclr) !important; */
  /* color: var(--box-shadow) !important; */
  background-color: #1469e2;
  color: white;
  /* font-size: 18px; */
}

.loginsign .nav-item a {
  /* color: #000; */
  color: #aaaaaa;
  background-color: #eeeeee;
}

.disabledbox {
  pointer-events: none;
  opacity: 0.5;
}

.successpay span {
  font-size: 40px;
  border: 1px solid #358a35;
  border-radius: 50%;
  padding: 9px;
}

.successpay p {
  font-size: 34px;
  font-weight: 500;
}

.successpay {
  color: #358a35;
  text-align: center;
}

.nobord {
  border: 0;
}

/* .............m.......... */

.upphoto button img {
  max-height: 143px;
  aspect-ratio: 1.5/1;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headerprof {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}

/* dispute */
.disputechart ul {
  background: #eef0f2;
  border-radius: 10px 10px 0 0;
}

.disputechart ul li {
  max-width: 140px;
}

.disputechart ul li button {
  background: #eef0f2 !important;
  border-color: #eef0f2 !important;
}

.disputechart ul li .nav-link.active {
  border-bottom: 4px solid var(--themeclr) !important;
  color: var(--themeclr);
  font-weight: 500;
}

.disputechart ul li .nav-link {
  color: #000;
}

.disputechat-lists .chatprof {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.disputechat-lists textarea {
  position: relative;
  height: 100px;
}

.disputechat-lists textarea::after {
  content: "";
  position: absolute;
}

.resortimg {
  position: relative;
}

.resortimg .chatprof {
  height: 50px !important;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  bottom: -25px;
  right: 20px;
}

.resortimg img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.nowrap {
  white-space: nowrap;
}

.disputetab img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.rdt_TableHeadRow .rdt_TableCol {
  background: #ececec;
  padding: 20px;
}

.rdt_TableCell .btn-success {
  padding: 2px 15px;
}

.rdt_TableCell .btn-danger {
  background: #fb4d20;
  border-color: #fb4d20;
  padding: 2px 15px;
}

.rdt_TableCell .btn-secondary {
  background: #d3d6d6;
  border-color: #d3d6d6;
  padding: 2px 15px;
}

.rdt_TableCell .btn-warning {
  background: #def5fa;
  border-color: #def5fa;
  color: #000 !important;
  border-radius: 0px;
  padding: 2px 15px;
  font-size: 14px !important;
  max-width: 105px !important;
}

.rdt_TableCell .btn-theme {
  padding-top: 4px;
  padding-bottom: 4px;
}

.split .rdt_TableCell {
  flex-direction: column;
  align-items: baseline !important;
  justify-content: center;
}

.btn.btn-chat {
  position: relative;
  background: #0c72d5;
  color: #fff;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  padding-right: 30px;
}

.btn.btn-chat span {
  position: absolute;
  background: #fff;
  color: #0c72d5;
  padding: 9px;
  border-radius: 50%;
  top: 0;
  right: -8px;
  box-shadow: 0 0 5px grey;
}

.togglebtn .btn.active {
  background: var(--themeclr1) !important;
  color: #fff;
  border-radius: 7px;
  border: 1px solid var(--themeclr1);
}

.togglebtn .btn {
  background: transparent !important;
  border: 1px solid var(--themeclr1);
  color: var(--themeclr1);
  border-radius: 7px;
  font-size: 14px;
  padding: 5px 30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  /* margin: 0 !important; */
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

/* sakthi */
/* Footer */
.footer-custom-container {
  max-width: 90%;
}

.footer-section .footer-head {
  padding: 50px 0px 0px 0px;
}

.footer-section .footer-custom-container .logo-section img {
  width: 125px;
  height: 125px;
  object-fit: contain;
}

.footer-section {
  background-color: #dcf3f8;
}

.footer-section .content-des {
  color: #222;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.footer-section .social-net {
  color: #3e3e3e;
  font-size: 22px;
  font-style: normal;
  line-height: normal;
}

.footer-section .copyright-content {
  color: #222;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.footer-section .bottom-footer {
  /* max-width: 73%; */
}

.footer-section hr:not([size]) {
  height: 2px !important;
}

.footer-section .terms-privacy {
  color: #445e64;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-decoration: none;
}

.footer-section .terms-privacy:hover {
  color: var(--orange) !important;
}

.footer-section .header-text {
  color: #222;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
}

.footer-section .sub-content {
  /* color: var(--orange); */
  color: #f5593e;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  display: block;
  line-height: 40px;
  text-decoration: underline;
  /* 200% */
}

.footer-section .terms-condition-section {
  display: flex;
  justify-content: end;
}

@media (max-width: 380px) {
  .footer-section .footer-custom-container .logo-section img {
    width: 80px !important;
    height: 80px !important;
    object-fit: contain;
  }

  .footer-section .copyright-content {
    font-size: 16px !important;
    line-height: 25px;
  }

  .footer-section .terms-privacy {
    line-height: 25px;
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .account-holder-name {
    font-size: 15px;
  }

  .profile-account-details h2 {
    font-size: 25px !important;
  }

  .account-name-listing {
    font-size: 20px !important;
  }

  .account-holder-email {
    font-size: 15px !important;
  }

  .footer-section .footer-custom-container .logo-section img {
    width: 94px;
    height: 94px;
    object-fit: contain;
  }

  .host-img-sec {
    width: 242px;
    height: 185px;
  }

  .typemsg button {
    width: 80px;
  }

  .footer-section .content-des {
    font-size: 15px;
  }

  .place-questions {
    height: 300px;
  }

  .propstype .back-btn {
    width: 100px;
  }

  .propstype .next-btn {
    width: 100px;
  }

  .footer-section .social-net {
    font-size: 21px;
  }

  .footer-section .social-net img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }

  .no-stat-cont p {
    font-size: 14px !important;
  }

  .footer-section .copyright-content {
    font-size: 16px;
  }

  .footer-section .terms-privacy {
    font-size: 16px;
  }

  .expdetails .loc_Details {
    display: flex;
    flex-direction: column;
    font-size: 15px;
  }

  .expdetails .logo {
    width: 55px !important;
    height: 42px !important;
    min-width: 55px !important;
    min-height: 42px !important;
  }

  .detail_heading {
    font-size: 21px !important;
  }
}

@media (max-width: 765px) {
  .footer-section .terms-condition-section {
    display: flex;
    justify-content: start;
    margin-top: 20px;
  }
}

@media (max-width: 991px) {
  .footer-section .bottom-footer {
    /* max-width: 90%; */
  }

  .feature_proper .header_sec {
    flex-wrap: wrap;
    gap: 20px;
  }

  .feature_proper .view-all-properties button {
    padding: 9px 15px;
    font-size: 16px !important;
  }

  .request-to-book-btn button {
    padding: 8px 50px !important;
    font-size: 16px !important;
  }
}

@media (max-width: 767px) {
  .place-questions {
    min-height: 400px;
    height: unset;
  }

  .map_cst_posti.map-custom-position {
    top: 323px !important;
    left: 25px !important;
  }

  .copyright-content {
    text-align: center;
  }

  .footer-section .terms-condition-section {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .card-img-section {
    min-height: auto;
  }

  .headercont {
    height: 40px;
  }

  .map-custom-position {
    top: 580px;
  }
}

/* sakthi */
/* 30-09-23 */
.loginsign .nav-pills .nav-item a {
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 218px; */
  height: 48px;
  font-size: 17px;
}

.country-phone-field {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.country-phone-field .input-group {
  width: 100%;
}

.welcome-to-flutterpads h6 .pro-title {
  color: #ec6043;
}

.welcome-to-flutterpads h6 {
  font-size: 18px;
  font-weight: 400;
  color: #3e3e3e;
}

.sign-up-custom-btn {
  width: 275px;
  height: 50px;
  background-color: #ec6043;
  border: none;
  outline: none;
  color: white;
  font-size: 18px;
  border-radius: 0px;
}

.sign-up-custom-btn:hover {
  background-color: black;
  color: white;
}

.custom-country-select {
  width: 155px;
}

.loginmodal select {
  border-radius: 0px !important;
  border: 1px solid rgba(62, 62, 62, 0.2);
}

.phone-no-input .input-group-text {
  border-radius: 0px;
}

.email-text-field .input-group-text {
  border-radius: 0px;
}

.email-text-field input {
  border-radius: 0px;
}

.phone-no-input input {
  border-radius: 0px;
}

.country-sel .input-group-text {
  border-radius: 0px;
  border: 1px solid rgba(62, 62, 62, 0.2);
}

.loginform .input-group-text {
  background-color: #eeeeee;
}

.or-login-with {
  margin-top: 30px;
  margin-bottom: 30px;
}

.login-privacy-policy {
  font-size: 13.5px;
}

.or-login-with span {
  background: white;
  display: inline-block;
  padding: 0px 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 2;
}

.continue-social {
  display: flex;
}

/* .continue-social button {
  width: 200px;
  height: 35px;
} */
@media (max-width: 320px) {
  /* .custom-country-select {
    width: 83% !important;
  } */
}

@media (min-width: 321px) and (max-width: 380px) {
  /* .custom-country-select {
    width: 84% !important;
  } */
}

@media (max-width: 380px) {
  .loginsign .nav-pills .nav-item a {
    /* width: 115px !important; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .reserve .nav.nav-pills {
    width: 650px;
  }
}

@media (min-width: 992px) {
  .reserve .nav.nav-pills {
    width: 800px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .custom-country-select {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .loginsign .nav-pills .nav-item a {
    /* width: 160px; */
  }
}

@media (max-width: 480px) {
  .country-phone-field {
    display: flex;
    flex-direction: column;
  }

  .country-phone-field .country-sel {
    flex-wrap: nowrap !important;
  }

  .custom-country-select {
    width: 100%;
  }

  .continue-social {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .kep-login-facebook.metro {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .google-btn button {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kep-login-facebook .fa {
    margin-right: 10px;
  }
}

@media (min-width: 381px) and (max-width: 480px) {
  /* .custom-country-select {
    width: 87% !important;
  } */
}

.dashboard-today-sec .head-sec .heading-txt {
  color: #000;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboard-today-sec .head-sec .descrip {
  font-weight: 500;
}

.button.cardbtn {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.25);
}

.video-player-1696152426645-dimensions {
  width: 100% !important;
  height: 600px !important;
}

.video-bgiii {
  width: 100%;
  height: 100vh;
}

.head-section .carousel-control-prev-icon,
.head-section .carousel-control-next-icon {
  display: none;
}

/* video play section*/

.video-container {
  position: relative;
}

video {
  max-width: 100%;
  /* max-height: 100vh; */
}

.video-control {
  position: absolute;
  left: 1em;
  bottom: 1em;
  min-width: 7.5em;
  padding: 0.5em 1em;
  text-align: left;
  border-radius: 9in;
  background: #fffb;
}

.video-control:not(.playing) .video-control-pause,
.video-control.playing .video-control-play {
  display: none;
}

.video-control-symbol {
  font: 1.75em/0 Apple Color Emoji;
  vertical-align: -0.15em;
}

.login-continue-btn {
  width: 270px;
  height: 50px;
}

.expdetails .experience_calendar .react-datepicker__week > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-all-img-btn {
  background: #ec6043 !important;
  color: white !important;
  border: none !important;
  outline: none !important;
  padding: 10px 10px;
  border-radius: 0px !important;
}

.show-all-img-btn:hover {
  color: #fff !important;
  background-color: var(--lite-black-sub-heading) !important;
  background: var(--lite-black-sub-heading) !important;

  color: white;
}

/* calender custom css */

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #ec6043 !important;
  color: #fff !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #ec6043 !important;
}

.contact-host-btn {
  background: #ec6043 !important;
  color: white !important;
  border: none !important;
  outline: none !important;
}

.become-a-host-header {
  color: rgba(0, 0, 0, 0.55);
}

.custom-container-filter-copy {
  max-width: 100% !important;
}

.rightheader .custom-navbar a {
  width: 100% !important;
  border-radius: 0px !important;
  border: none !important;
}

.filtermap-map {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

@media (max-width: 576px) {
  .filtermap-map {
    height: 50vh;
  }
}

@media (min-width: 577px) and (max-width: 991px) {
  .filtermap-map {
    height: 80vh;
  }

  .video-bg-btn button {
    width: 75px !important;
    height: 75px !important;
  }

  .video-bg-btn .play-icon {
    width: 25px !important;
    height: 25px !important;
  }

  .video-bg-btn .pause-icon {
    width: 33px !important;
    height: 33px !important;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  .custom-container-filter-copy .filter-section-location {
    width: 18% !important;
  }

  .custom-container-filter-copy .filter-section-dec-wid {
    /* width: 20% !important; */
  }

  .experience_filter_map .departure-sec-custom {
    padding-left: 100px !important;
  }

  .custom-container-filter-copy .search-btn button {
    /* width: 100% !important; */
  }

  .custom-container-filter-copy .location input,
  .filter .filter-section .departure-sec input {
    /* width: 80px !important; */
  }

  .custom-container-filter-copy .arrival-sec {
    padding-left: 0.5rem !important;
  }
}

.account-details .account {
  color: var(--lite-black-heading);
}

.account-details .account-name {
  color: var(--lite-black-sub-heading);
}

.account-details .email {
  font-weight: 400;
}

@media (min-width: 992px) and (max-width: 1400px) {
  .host-img-sec {
    width: 300px;
    height: 300px;
    object-fit: contain;
  }

  .place-questions p {
    font-size: 20px;
  }
}

@media (min-width: 1401px) {
  .host-img-sec {
    width: auto !important;
    height: auto !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .d_mbo_datete {
    display: block !important;
  }

  .inout_picker_to_div {
    margin-top: 15px !important;
  }

  .host-img-sec {
    width: 250px;
    height: 250px;
    object-fit: contain;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .host-img-sec {
    width: 190px;
    height: 190px;
    object-fit: contain;
  }

  .place-questions p {
    font-size: 18px;
  }
}

.login-page-sign-up-btn {
  width: 100%;
}

.login-page-sign-up-btn .nav-item {
  width: 47%;
}

.footer-section .sub-content:hover {
  cursor: pointer;
  /* color: var(--orange); */
  color: #445e64;
}

.insta-img:hover,
.fb-img:hover,
.twitter-img:hover {
  cursor: pointer;
  /* margin-top: -20px !important; */
}

.continue-exploring button:hover {
  cursor: pointer;
  background-color: var(--lite-black-sub-heading);
  color: white;
}

.continue-exploring button:focus {
  background-color: var(--orange);
  color: white;
}

.personalinfo .input-group {
  border: 0.2px solid rgba(62, 62, 62, 0.3);
  background: #fff;
}

.floating-label p {
  font-size: 13px;
  color: var(--lite-black-heading);
  font-weight: 500;
  margin-bottom: 5px;
  /* position: absolute; */
  /* left: 13px; */
  /* top: 6px; */
}

.floating-label {
  /* border: 1px solid #ced4da;
  height: 55px;
  position: relative;
  border-radius: 5px; */
}

.floating-label input {
  /* border: none;
  margin-top: 17px; */
}

.floating-label .input-group input {
  margin-top: 0px !important;
  border-radius: 0px !important;
}

.personalinfo .input-group .input-group-text {
  border-radius: 0px !important;
  background-color: #eee;
  border-left: 0.2px solid rgba(62, 62, 62, 0.3);
}

/* .personalinfo .form-control{
  appearance: none !important;
} */
.floating-label select {
  /* border: none;
  height: 53px; */
}

.name-input-group {
  border: none !important;
}

.name-input-group .form-control {
  appearance: none;
}

.personal-info-right-side-sec .heading {
  color: var(--blue);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 166.667% */
}

.personal-info-right-side-sec .description {
  color: var(--lite-black-heading);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.profile .bordbox {
  background: #fff !important;
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.15) !important;
  border: 0px !important;
  /* width: 350px;
  height: 380px; */
}

.bordbox h6 {
  color: #3e3e3e;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
  /* 150% */
}

.bordbox p {
  color: #3e3e3e;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
  text-align: center;
}

.img-upload-custom-btn {
  width: 37px;
  height: 40px;
  position: relative;
}

.img-upload-custom-btn input {
  width: 37px;
  height: 44px;
  /* background: red; */
  z-index: 2;
  position: relative;
  padding: -2px;
  border-radius: 93%;
  display: inline-block;
  padding: 5px;
  opacity: 0 !important;
  cursor: pointer;
}

.img-upload-custom-btn img {
  width: 35px !important;
  height: 36px !important;
  position: absolute;
  left: 14px;
  top: 7px;
}

.editimg {
  position: absolute;
  /* right: 124px;
  bottom: 0px; */
  margin-top: 83px;
  margin-left: 36px;
}

.profile-account-details h2 {
  color: var(--lite-black-heading);
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 78.947% */
}

.profile-account-details p {
  color: var(--lite-black-heading);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.account-name-listing {
  color: var(--lite-black-heading);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profile-account-details .editprofbtn {
  border-radius: 5px;
  border: 0.2px solid rgba(62, 62, 62, 0.5);
  background: #eee;
  margin-left: 10px;
}

.profile-account-details button svg {
  color: #aaaaaa;
}

@media (min-width: 767px) and (max-width: 991px) {
  .profile-account-details h2 {
    font-size: 33px;
  }
}

.become-host-card-bottom-style.active {
  border: 2px solid #a7aba6;
}

.become-host-card-bottom-style {
  width: 100%;
  border: none;
  background: #fff;
  box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  height: 191px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid transparent;
}

.become-host-card-bottom-style:hover {
  border: 2px solid #a7aba6;
}

.become-host-card-bottom-style img {
  border-radius: 5px;
  max-width: 100px;
  height: 100px;
  width: 100%;
}

.property-type .centerhead {
  visibility: hidden;
}

.property-type .mobile-filter {
  display: none;
}

.property-type .dropdown-menu.show {
  transform: translate(3px, 39px) !important;
}

.become-host-card-bottom-style p {
  height: 10px;
  /* background-color: #fba61e; */
}

.p-bg-color-1 {
  background: #fba61e;
}

.p-bg-color-2 {
  background-color: #18bbc2;
}

.p-bg-color-3 {
  background-color: #1469e2;
}

.modal_style .modal-header {
  padding-top: 40px !important;
}

.modal_style .modal-header .close_modal {
  position: absolute;
  top: 9px !important;
  background: #dee2e6;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  z-index: 999 !important;
}

.modal_style .modal-header .close_modal span {
  line-height: 30px;
  font-size: 14px !important;
}

.cover_oda_txt p {
  font-size: 14px !important;
}

.cover_oda_txt h6 {
  font-size: 16px !important;
}

.placof_ul {
  margin-bottom: 0px !important;
}

a.text-dark {
  color: var(--orange) !important;
}

a.text-dark:hover {
  color: #000 !important;
}

body::-webkit-scrollbar,
.modal-dialog-scrollable .modal-body::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar,
.rightcont::-webkit-scrollbar,
.messages::-webkit-scrollbar,
.msg_sec_scrol::-webkit-scrollbar,
.card-filter-sec-custom::-webkit-scrollbar,
.scroll_review_sec::-webkit-scrollbar,
.drop-down.show .drop-items .itemss>div.scrolablediv::-webkit-scrollbar,
.expsteps_repo::-webkit-scrollbar,
.scroll_new_host::-webkit-scrollbar,
.disputechat-lists::-webkit-scrollbar
/* .disputetab.shadowbox>div:first-child */


 {
  /* width: 0.5em; */
  /* background-color: #ccc; */
}

.table-responsive::-webkit-scrollbar,
.modal-dialog-scrollable .modal-body::-webkit-scrollbar,
.rightcont::-webkit-scrollbar,
.messages::-webkit-scrollbar,
.disputetab.shadowbox>div:first-child::-webkit-scrollbar,
.stays_tabe::-webkit-scrollbar,
.expr_tabe::-webkit-scrollbar,
.toggle_ho_scr::-webkit-scrollbar,
.card-filter-sec-custom::-webkit-scrollbar,
.reserve .nav.nav-pills::-webkit-scrollbar,
.scroll_review_sec::-webkit-scrollbar,
.expsteps_repo::-webkit-scrollbar,
.scroll_new_host::-webkit-scrollbar
/* .drop-down.show .drop-items .itemss > div.scrolablediv::-webkit-scrollbar */

 {
  height: 0.5rem;
}

body::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:vertical,
.modal-dialog-scrollable .modal-body::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb,
.rightcont::-webkit-scrollbar-thumb,
.messages::-webkit-scrollbar-thumb,
.disputetab.shadowbox > div:first-child::-webkit-scrollbar-thumb,
.stays_tabe::-webkit-scrollbar-thumb,
.expr_tabe::-webkit-scrollbar-thumb,
.msg_sec_scrol::-webkit-scrollbar-thumb,
.toggle_ho_scr::-webkit-scrollbar-thumb,
.card-filter-sec-custom::-webkit-scrollbar-thumb,
.reserve .nav.nav-pills::-webkit-scrollbar-thumb,
.drop-down.show .drop-items .itemss > div.scrolablediv::-webkit-scrollbar-thumb,
.expsteps_repo::-webkit-scrollbar-thumb,
.scroll_new_host::-webkit-scrollbar-thumb,
.disputechat-lists::-webkit-scrollbar-thumb {
  height: 5px;
  /* background-color: #9e9e9e; */
}

html,
body,
.modal-dialog-scrollable .modal-body,
.table-responsive,
.rightcont,
.messages,
.disputetab.shadowbox > div:first-child,
.stays_tabe,
.expr_tabe,
.msg_sec_scrol,
.toggle_ho_scr,
.card-filter-sec-custom,
.reserve .nav.nav-pills,
.card-filter-sec-custom,
.scroll_review_sec,
.drop-down.show .drop-items .itemss > div.scrolablediv,
.expsteps_repo,
.scroll_new_host,
.disputechat-lists {
  scrollbar-color: #f0e7e7 transparent;
  /* scrollbar-width: 1px !important; */
}

/* body{
  scrollbar-color: #f0e7e7 transparent;
} */
body::-webkit-scrollbar {
  width: 3px !important;
}

/* body::-webkit-scrollbar {
  width: 5px;
}


body::-webkit-scrollbar-track {
  background: #1b242f;
}


body::-webkit-scrollbar-thumb {
  background: #04c2c9;
}


body::-webkit-scrollbar-thumb:hover {
  background: #04c2;
} */

.head-section .head-sec .view-all-properties button:hover,
.filter .filter-section .search-btn button:hover {
  background-color: var(--lite-black-sub-heading);
}

.footer-section .social-net {
  transition: all 0.5s ease-in;
}

.error_dov {
  display: flex;
  flex-direction: column;
}

.orsnge_y {
  color: #ec6043;
}

.orsnge_y:hover {
  color: #000;
}

.dd_menu_shadow a:last-child {
  margin-bottom: 0px !important;
}

.btn-outline-dark {
  width: 100%;
  border: 1px solid #1b1b1b;
  color: #1b1b1b;
  border-radius: 0px;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
}

.btn-outline-dark:hover {
  border: 1px solid #1b1b1b;
  background-color: #1b1b1b;
}

.rightcont ul {
  padding-bottom: 20px !important;
}

.autocomplete-dropdown-container {
  font-size: 14px !important;
}

.location-search-input,
.gropform input {
  height: 55px;
  margin-bottom: 10px;
  border-radius: 0px;
}

form p.text-danger {
  font-size: 13px !important;
  position: relative !important;
}

.input_with_ma .input-group-text {
  border-top-left-radius: 15px !important;
}

.input_with_ma input {
  border-top-right-radius: 15px !important;
}

.addrss_div_j {
  box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.15);
  height: unset !important;
}

.addrss_div_j .currentloc {
  padding-left: 8px !important;
  margin-bottom: 0px;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}

.guder_tpr_e {
  display: flex;
  align-items: center;
}

.uploadedpics .fullbutton {
  background-color: transparent !important;
  border: none !important;
}

.uploadedpics .fullbutton:hover {
  /* border-color: transparent !important; */
}

.images button:hover .uploadimage {
  border-color: transparent !important;
}

.placetype {
  min-height: 400px;
}

textarea,
textarea.form-control {
  border: 0.2px solid rgba(62, 62, 62, 0.3);
  background: #fff;
  border-radius: 0px;
}

.input_big_ne {
  border: 0.2px solid rgba(62, 62, 62, 0.3);
  border-radius: 0px;
  /* min-height: 55px; */
  min-height: 45px;
}

.custom_phone_inp {
  width: 100%;
  height: 45px;
  margin-top: 0.25rem;
}

.custom_phone_inp .form-control {
  width: 100% !important;
  height: 100% !important;
}

.h5_lie_nmo {
  line-height: 1.8 !important;
}

.start_jps_box {
  padding: 15px;
}

.bordbox.start_jps_box p.text-left {
  text-align: left !important;
}

.fotmcon_big {
  border-radius: 0px;
  height: 55px;
}

.person_info_edit .input-group-text {
  padding-left: 13px;
  padding-right: 13px;
}

.personalinfo .input-group .input-group-text button {
  border: none !important;
}

.personalinfo .input-group input {
  /* border-top:none !important;
  border-left:none !important; */
  border-radius: 0px !important;
}

.personal-info-right-side-sec .bord {
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
  box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.15);
  border-radius: 0px !important;
}

.inut_grp_id_c input,
.inut_grp_id_c .input-group-text {
  border-radius: 0px !important;
}

.mt_new_aho_a {
  margin-top: 120px !important;
}

.calendar .react-datepicker__header {
  background-color: #f8f9fa !important;
  border-color: #ced4da !important;
  border-radius: 0px !important;
}

.calendar .react-datepicker__month-container {
  border-radius: 0px !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #f8f9fa !important;
  color: #000 !important;
}

.react-datepicker__header {
  background-color: #ebeff2 !important;
}

.react-datepicker {
  /* border: 1px solid #ced4da !important; */
}

.react-datepicker__month-container .react-datepicker__header:first-child {
  border-top: none !important;
}

.calendar .react-datepicker__navigation--next {
  right: 10px !important;
  top: 5px !important;
}

.calendar .react-datepicker__navigation-icon--previous {
  left: 10px !important;
  top: 5px !important;
}

.notificationss p {
  font-size: 14px !important;
}

.listings .nav-pills .nav-link {
  color: #000;
}

.listings .nav-pills .nav-link.active,
.listings .nav-pills .show > .nav-link {
  background-color: #ec6043;
  border-radius: 0px;
  color: #fff;
}

.dropdown-menu.show a {
  text-decoration: none !important;
}

.footer-section .social-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  cursor: pointer;
  background-color: #f5593e;
}

.footer-section .social-icon:hover {
  /* color: var(--orange); */
  background-color: #39565b;

  color: var(--white);
}

.msg_bg {
  background: #ebeff2;
}

.msg_noco {
  min-height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-experience .logo-img {
  width: 80px;
  height: 71px;
  object-fit: contain;
}

.no_chat_img {
  max-width: 200px;
}

.cat_wit_r {
  font-size: 16px;
}

.btn-theme-dark {
  background-color: #1b1b1b;
  color: #fff !important;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
  min-width: 100px;
  border-radius: 8px;
  border-color: #1b1b1b !important;
  color: white;
  border-radius: 0px;
}

.btn-theme-dark:hover {
  background: #ec6043 !important;
  border-color: #ec6043 !important;
}

.border_rad_0 {
  border-radius: 0px !important;
}

.tripstay_tab1 .rdt_TableHeadRow div:first-child,
.tripstay_tab1 .rdt_TableRow div:first-child,
.tripstay_tab2 .rdt_TableHeadRow div:first-child,
.tripstay_tab2 .rdt_TableRow div:first-child,
.tripstay_tab3 .rdt_TableHeadRow div:first-child,
.tripstay_tab3 .rdt_TableRow div:first-child {
  min-width: 200px;
}

.dis_tab .rdt_TableHeadRow div:first-child,
.dis_tab .rdt_TableRow div:first-child {
  max-width: 50px !important;
}

.dis_tab .rdt_TableHeadRow div:last-child,
.dis_tab .rdt_TableRow div:last-child {
  min-width: 200px !important;
}

.font_cot_big {
  border: 1px solid rgba(62, 62, 62, 0.3);
  background: #fff;
  border-radius: 0px;
  min-height: 55px;
}

.er_text {
  font-size: 16px !important;
}

.btn-theme.btn_them_grey {
  background-color: #f8f9fa !important;
  border-color: #ced4da !important;
  border-radius: 0px !important;
  color: #000 !important;
  cursor: pointer;
}

.btn-theme.btn_them_grey:hover {
  background-color: #1b1b1b !important;
  border-color: #1b1b1b !important;
  color: #fff !important;
}

.payment_sc_text {
  color: var(--orange) !important;
  font-weight: 600 !important;
  margin-top: 20px !important;
}

.btn-success,
.btn-danger {
  border-radius: 0px !important;
}

.rdt_TableCell .btn-success {
  background: #26d383;
  border-color: #26d383;
  color: #fff !important;
  border-radius: 0px;
  padding: 2px 15px;
  font-size: 14px !important;
  min-width: 105px !important;
}

.rdt_TableCell .btn-danger {
  background: #fb4d20;
  border-color: #fb4d20;
  color: #fff !important;
  border-radius: 0px;
  padding: 2px 15px;
  font-size: 14px !important;
  min-width: 105px !important;
}

.name_cancl {
  color: #ec6043;
  font-weight: 600;
}

.info-box-max {
  max-width: max-content !important;
}

.host-dashboard-img-sec {
  /* width: 100%; */
  /* height: 100vh; */
  /* object-fit: contain; */
  width: 240px;
}

.host-dashboard-img-sec img {
  /* width: 100%; */
  /* height: 100%; */
  /* object-fit: contain; */
}

.toggle_ho_scr {
  display: inline-block;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  /* padding-bottom: 20px; */
  /* margin-left: -10px; */
}

.toggle_ho_scr .btn {
  display: inline !important;
}

@media only screen and (min-width: 992px) {
  .toggle_ho_scr {
    padding-bottom: 0px !important;
  }
}

.manage-experience .dropdown-menu {
  border: none !important;
  border-radius: 0px !important;
}

.adminblue:hover {
  color: #000 !important;
}

.button_div_map {
  position: fixed;
  bottom: 20px;
  background-color: #8b85853b;
  width: 120px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  padding: 10px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .card-img-section {
    min-height: 330px !important;
  }

  .footer-section .copyright-content,
  .footer-section .terms-privacy {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-section .bottom-footer {
    /* width: 80% !important; */
    /* max-width: 80% !important; */
  }
}

@media only screen and (max-width: 468px) {
  .col_filter_align {
    position: relative;
    /* margin-bottom: 75px; */
    width: 100%;
    /* grid-column-gap: 1%; */
    /* display: grid !important; */
    /* grid-template-columns: 49% 49%; */
  }

  .col_filter_align .dd_dropdown_card button,
  .col_filter_align .departure-sec.custom-width-collapse .d-flex {
    /* justify-content: flex-start !important; */
  }

  .col_filter_align .departure-sec.custom-width-collapse .d-flex {
    /* padding-left: 10px !important; */
  }

  /* .col_filter_align .search-btn.custom-width-collapse button {
    position: absolute;
    right: 0px;
    top: 150px;
  } */
}

@media only screen and (min-width: 469px) and (max-width: 520px) {
  .col_filter_align {
    position: relative;
    /* margin-bottom: 75px; */
    width: 100%;
    /* grid-column-gap: 1%;
    display: grid !important;
    grid-template-columns: 49% 49%; */
  }

  .col_filter_align .dd_dropdown_card button,
  .col_filter_align .departure-sec.custom-width-collapse .d-flex {
    /* justify-content: flex-start !important; */
  }

  .col_filter_align .departure-sec.custom-width-collapse .d-flex {
    padding-left: 10px !important;
  }

  /* .col_filter_align .search-btn.custom-width-collapse button {
    position: absolute;
    right: 0px;
    top: 150px;
  } */
}

.modal_view_img .showallimg .item img {
  max-width: 100% !important;
}

.graybox.br-0 {
  border-radius: 0px !important;
}

.btn_clear_link {
  border: none !important;
  background-color: transparent !important;
  color: #000;
  text-decoration: underline;
  padding: 0px !important;
}

.btn_clear_link:hover {
  color: #e93d19;
}

@media only screen and (min-width: 768px) {
  .host-dashboard-img-sec {
    /* min-height: unset !important; */
  }
}

.expcontcontainer .input_with_ma input.form-control {
  border-top-right-radius: 15px !important;
}

.expcontcontainer .address {
  width: calc(100% - 33px) !important;
}

.map_cst_posti button.currentloc .fa {
  max-height: 30px;
  min-height: 30px;
  min-width: 30px;
  max-width: 30px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.map_cst_posti .addrss_div_j .currentloc {
  padding-left: 5px !important;
}

.map-custom-position.map_cst_posti {
  top: 150px !important;
}

@media only screen and (max-width: 480px) {
  .googlelogin button {
    width: 100% !important;
  }
}

.reate-title-scroll {
  padding-right: 20px;
}

.expmap .shadowbox {
  padding: 20px !important;
}

.border_box_left_p.bordbox p {
  text-align: left !important;
}

@media (max-width: 767px) {
  .map_cst_posti.map-custom-position {
    top: 323px !important;
    left: 25px !important;
  }
}

.custom-width-collapse.custom-width-collapse-new-cal {
  width: 100% !important;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  /* padding-bottom: 0px !important; */
  padding-bottom: 12px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.scroll_review_sec {
  max-height: 200px;
  overflow-y: auto;
}

/* .modal_view_img .modal-dialog
{
  min-height: 90% !important;
  height: 90% !important;
} */

/* kr css */
.textcounter {
  margin: 0;
  padding: 0;
  font-size: 13px;
}

.message_counter {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 70%;
  width: 70%;
}

/* end of kr css */

/* calendar css */
.fc .fc-col-header-cell-cushion,
.fc .fc-daygrid-day-number {
  color: #000 !important;
  text-decoration: none !important;
  text-align: center;
}

.fc .fc-daygrid-day-frame {
  min-height: 100%;
  position: relative;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.fc .fc-button-primary {
  background-color: #1b1b1b !important;
  border-color: #1b1b1b !important;
}

.fc .fc-button-primary:focus {
  box-shadow: rgba(76, 91, 106, 0.5) 0px 0px 0px 0rem !important;
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:hover {
  background-color: #ec6043 !important;
  border-color: #ec6043 !important;
}

.fc .fc-button-primary {
  border-radius: 0px !important;
}

.fc-view-harness.fc-view-harness-active {
  min-height: 425px !important;
}

/* .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame
{
  justify-content:flex-start !important;

}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-top
{
  margin-top: 12px !important;
} */
.fc-h-event {
  background-color: #ec6043 !important;
  border-color: #ec6043 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  color: #ec6043 !important;
}

.fc-daygrid-event-dot {
  border-color: #ec6043 !important;
}

.fc .fc-daygrid-day-top {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 25px;
}

.fc .fc-col-header-cell-cushion,
.fc .fc-daygrid-day-number {
  align-items: center;
  display: flex;
  justify-content: center;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 20px !important;
  max-height: 20px !important;
}

@media only screen and (max-width: 575px) {
  .fc .fc-toolbar {
    flex-direction: column !important;
  }

  .fc-toolbar-title {
    margin-bottom: 10px !important;
  }

  .flex_time_div input {
    min-width: 100px !important;
    max-width: 100px !important;
  }

  .flex_time_div p {
    font-size: 14px !important;
  }
}

.flex_time_div {
  align-items: center;
  display: flex;
  justify-content: center;
}

.flex_time_div input {
  min-width: 130px;
  max-width: 130px;
  border: 1px solid #ced4da;
  min-height: 36px;
  font-size: 14px;
  padding: 0px 10px;
  background: url(../images/icons/new/clock.png);
  background-repeat: no-repeat;
  background-size: 15px !important;
  background-position: top 8px right 8px;
}

.flex_time_div .react-datepicker__month-container {
  display: none !important;
}

.flex_time_div .react-datepicker__time-container {
  min-width: 130px !important;
}

.flex_time_div
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  min-width: 130px !important;
}

.flex_time_div
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #ebebeb;
  color: #000 !important;
}

.flex_time_div
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  color: #000 !important;
}

@media only screen and (min-width: 1200px) {
  .stikyavail.stikyavail_cal {
    position: sticky;
    top: 100px;
    height: 100%;
  }
}

.show_detail_dta p {
  margin-bottom: 10px;
}

/* end calendar css */

/* MAP CARD CSS */
/* / Hari css / */
/* / Hari css / */

.mapvei_card .map_wraper {
  height: 250px;
  min-width: 280px;
  max-width: 280px;
  background: red;
  border-radius: 10px;
}

.mapvei_card .map_wraper .map_wrap_slider {
  height: 70%;
  background-color: green;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.min_card_width {
  min-width: 300px;
  padding: unset !important;
  transform: translate(-40%, 10px);
}

.pad_left_rig {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.border_radius_left_right {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  overflow: hidden !important;
  border: 1px solid #ccc;
}

.close_icon_x {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--second);
  cursor: pointer;
  font-size: 16px;
}

.z-ind {
  z-index: 100000 !important;
}

.check_div {
  width: 200px;
  height: 200px;
  background-color: red !important;
  padding: 10px;
}

/* .yellownav .owl-nav button {
  opacity: 1 !important;
} */
@media (max-width: 767px) {
  .min_card_width {
    transform: translate(-46%, 10px);
  }
}

/* END MAP CARD CSS */

.btn_info_tdan {
  background-color: transparent !important;
  border: none !important;
}

.btn_info_tdan:hover {
  color: #f5593e !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.title_star_listi {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.list_new_car .owl-carousel .owl-nav button.owl-prev,
.list_new_car .owl-carousel .owl-nav button.owl-next,
.list_new_car .owl-carousel button.owl-dot {
  background: #d6d6d6 !important;
  padding: 10px 20px !important;
}

.mapvei_card .map_wraper {
  height: 250px;
  min-width: 280px;
  max-width: 280px;
  background: red;
  border-radius: 10px;
}

.mapvei_card .map_wraper .map_wrap_slider {
  height: 70%;
  background-color: green;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.min_card_width {
  min-width: 300px;
  padding: unset !important;
  transform: translate(-40%, 10px);
}

.pad_left_rig {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.border_radius_left_right {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  overflow: hidden !important;
}

.close_icon_x {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--second);
  cursor: pointer;
  font-size: 16px;
}

.z-ind {
  z-index: 100000 !important;
}

.check_div {
  width: 200px;
  height: 200px;
  background-color: red !important;
  padding: 10px;
}

/* .yellownav .owl-nav button {
  opacity: 1 !important;
} */
@media (max-width: 767px) {
  .min_card_width {
    transform: translate(-46%, 10px);
  }
}

/* END MAP CARD CSS */

.btn_info_tdan {
  background-color: transparent !important;
  border: none !important;
}

.btn_info_tdan:hover {
  color: #f5593e !important;
}

/* Hari CSS 18-10-23 */

.pad_y {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.cont_subtle {
  line-height: 1.8;
}

.ph_txt {
  color: #f5593e;
  font-weight: 600;
}

.ph_icon_clr {
  color: #f5593e;
}

.strt_txt {
  font-weight: 500;
}

.acc_new_btn {
  background: transparent !important;
  color: black !important;
  font-weight: 500;
  transition: 0.2s;
  font-size: 15px;
  padding: 10px 10px;
  box-shadow: unset !important;
}

.acc_new_btn:hover {
  color: #f5593e !important;
}

.acc_new_btn:focus {
  box-shadow: unset !important;
}

.acc_itm_pad {
  padding: 10px 10px !important;
}

.acc_body {
  padding: 10px 10px !important;
}

.acc_itm_pad:first-of-type {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.acc_itm_pad:last-of-type {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.acc_ {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
}

.acc_subtle {
  font-weight: 500;
  font-size: 15px;
}

.acc_p {
  font-weight: 400 !important;
  line-height: 1.8;
  font-size: 15px;
}

.acc_new_btn::after {
  filter: grayscale(1) !important;
}

.policy_tle {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 17px;
}

.policy_ {
  font-weight: 500;
}

.bold_txt_sm {
  font-weight: 500;
  font-size: 14px;
}

.grey_p_txt {
  color: grey;
  line-height: 1.7;
}

.grey_txt_bold {
  color: grey;
  font-weight: 600;
}

/* about section css */

.about_s {
  font-size: 2.2rem;
  font-weight: 500;
}

.about_banner_p {
  font-weight: 500;
  line-height: 2;
  font-size: 16px;
}

.revolve_p {
  font-size: 28px;
  font-weight: 450;
}

.revolve_p span {
  color: #f5593e;
}

.about_count_bg {
  background-color: #f3f3f2;
}

.count_tle {
  font-size: 20px;
  font-weight: 500;
}

.count_dls h2 {
  font-size: 25px;
  font-weight: 500;
  margin-top: 10px;
}

.count_dls p {
  font-weight: 500;
  font-size: 12px;
}

.dream_banner_p {
  font-weight: 500;
  line-height: 2;
  font-size: 13px;
}

.img_bor_radius {
  border-radius: 30px;
}

.abt_btns {
  border: none !important;
  outline: none !important;
  padding: 20px 25px;
  font-weight: 500;
  border-radius: 10px;
}

/* media query */

@media (min-width: 576px) {
  .count_dls h2 {
    font-size: 35px;
    font-weight: 500;
    margin-top: 10px;
  }

  .dream_banner_p {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .pad_y {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .revolve_p {
    font-size: 30px;
    font-weight: 450;
  }

  .about_s {
    font-size: 2.8rem;
  }

  .acc_itm_pad {
    padding: 25px 25px !important;
  }

  .acc_ {
    font-size: 2.5rem !important;
    font-weight: 400 !important;
  }

  .acc_subtle {
    font-weight: 500;
    font-size: 1.1rem;
  }

  .acc_new_btn {
    font-size: 1.2rem;
    padding: 1rem 1.25rem;
  }

  .acc_body {
    padding: 1rem 1.25rem !important;
  }

  .acc_p {
    font-weight: 400 !important;
    line-height: 1.8;
    font-size: 16px;
  }

  .about_banner_pads {
    padding-left: 40px;
    padding-right: 40px;
  }

  .about_banner_p {
    font-size: 20px;
  }

  .count_dls h2 {
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 3px;
    margin-top: 10px;
  }

  .count_dls p {
    font-weight: 500;
    font-size: 16px;
  }

  .count_tle {
    font-size: 30px;
    font-weight: 500;
  }

  .dream_banner_p {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .revolve_p {
    font-size: 35px;
    font-weight: 450;
  }
}

@media (min-width: 1200px) {
  .count_dls h2 {
    font-size: 75px;
  }

  .pad_y {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media (max-width: 575px) {
  .pad_top_zero {
    padding-top: 0px !important;
  }

  .revolve_p {
    font-size: 20px;
    font-weight: 450;
  }
}

.col_heght_righ_new .rightcont {
  height: unset !important;
  min-height: unset !important;
  overflow: unset !important;
}

.placetype.placetype_he_us {
  height: unset !important;
}

.brand_log_sm {
  max-width: 50px;
}

.noti_new_link {
  font-style: italic;
  color: #f5593e !important;
  display: block;
  padding-left: 20px !important;
  padding-right: 15px !important;
  padding-bottom: 15px !important;
  cursor: pointer;
}

.noti_new_link:hover {
  color: #000 !important;
}

.rightcont_margn {
  padding: 20px 20px;
  margin-top: -20px;
}

.expdetails.with_footer_exp .footer-section {
  padding-bottom: 20px !important;
}

.form_chekc_nw .form-check {
  position: relative !important;
  padding-left: 0px !important;
  justify-content: flex-start !important;
  margin-left: -25px !important;
}

.form_chekc_nw {
  margin-top: 20px;
}

.form_chekc_nw label {
  font-size: 14px !important;
  padding-left: 55px !important;
}

.form_chekc_nw input {
  position: absolute;
  left: 22px;
  margin-top: 0px !important;
  top: 0px;
  padding: 0px !important;
  width: 20px;
  max-height: 20px !important;
  max-width: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
  min-height: 20px !important;
  margin-left: 0px !important;
}

@media only screen and (max-width: 488px) {
  .form_chekc_nw .form-check {
    margin-left: 0px !important;
  }

  .form_chekc_nw label {
    padding-left: 30px !important;
  }

  .form-check .form-check-input {
    /* margin-left: -25px !important; */
  }
  .stikybtns .form-check .form-check-input {
    margin-left: -20px !important;
  }
}

.icon_not_count {
  background-color: #1b1b1b;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 10px;
  font-size: 13px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -9px;
  right: 0px;
  z-index: 1;
}

.not_wih_o {
  position: relative;
}

.acc-box p.price_tetx_sub {
  color: #000;
  font-weight: 600;
  font-size: 20px !important;
}

.acc-box p.price_txt_new_sub {
  color: #ec6043;
  font-weight: 600;
  font-size: 20px !important;
}

.btn_payemnt {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 0px;
  color: #000;
  padding: 5px 20px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  margin-right: 10px;
}

.btn_payemnt:hover {
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px !important;
}

.btn_payemnt img {
  max-width: 25px;
  margin-right: 10px;
}

.acc-box.accox_sm {
  width: 320px !important;
}

@media only screen and (max-width: 400px) {
  .accox_sm.acc-box {
    max-width: 280px !important;
  }
}

@media only screen and (max-width: 575px) {
  .btn_payemnt {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (min-width: 768px) {
  .accox_sm.acc-box {
    /* max-width: 350px !important; */
    width: 95% !important;
  }
}

.plansrow_sub .font_pers {
  color: #000 !important;
  font-size: 30px !important;
}

.btn_theme_black {
  background-color: #1b1b1b;
  color: #fff !important;
  border-radius: 0px;
  font-size: 14px !important;
  min-width: 150px;
}

.btn_theme_black:hover {
  background-color: #ec6043;
}

.subscribe_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 375px;
  margin: 0 auto;
}

.scroll_new_host {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 10px;
}

.content_wrap_1,
.content_wrap_2 {
  word-break: break-word;
}

.input_grp_with_attach textarea {
  max-width: calc(100% - 35px);
  border: none !important;
}

.input_grp_with_attach .btn-theme {
  max-width: 30px !important;
  width: 30px !important;
  background: transparent !important;
  color: #000 !important;
  position: unset !important;
  min-width: 30px !important;
  z-index: 0 !important;
}

.input_grp_with_attach .input-group-append {
  max-width: 30px !important;

  /* cursor: pointer; */
}

.input_grp_with_attach .choosefiles_new {
  position: absolute;
  right: 5px;
  top: 0px;
  max-height: 53px;
  align-items: center;
  justify-content: center;
  min-height: 53px;
  display: flex;
  cursor: pointer;
}

.input_grp_with_attach .choosefiles_new input {
  max-width: 30px !important;
  opacity: 0;
  right: 5px;
  position: absolute;
  z-index: 2 !important;
  min-width: 30px !important;
  cursor: pointer;
}

.input_grp_with_attach {
  max-width: calc(100% - 155px);
}

.input_grp_with_attach {
  border: 0.2px solid rgba(62, 62, 62, 0.3) !important;
}

@media only screen and (max-width: 480px) {
  .input_grp_with_attach {
    max-width: calc(100% - 90px) !important;
  }
}

.input_with_icon_datpicker {
  font-size: 14px;
  color: gray;
  border-radius: 0px !important;
  border: 1px solid #ced4da;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.input_with_icon_datpicker input,
.input_with_icon_datpicker input:hover,
.input_with_icon_datpicker input:focus,
.input_with_icon_datpicker input:focus-visible {
  border: none !important;
  font-size: 14px;
  color: gray;
  outline: 0px !important;
  padding-top: 0px !important;
  width: 100%;
}

.input_with_icon_datpicker .react-datepicker-wrapper {
  position: relative;
  width: 100%;
}

.input_with_icon_datpicker .react-datepicker-wrapper::after {
  content: "";
  position: absolute;
  top: 20px;
  right: 10px;
  width: 15px;
  height: 15px;
  background: url(../images/icons/calendar_new.png);
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.inout_picker_to_div {
  max-width: 55px !important;
  margin-top: 20px !important;
}

@media only screen and (max-width: 575px) {
  .react-datepicker {
    /* display: flex !important; */
  }

  .experience_calendar .react-datepicker {
    display: block !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 1.4rem !important;
    line-height: 1.4rem !important;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}

.react_tele .react-tel-input {
  /* border: 0.2px solid rgba(62, 62, 62, 0.3); */
  background: #fff;
  height: 55px;
  border-radius: 0px !important;
}

.react_tele .react-tel-input input {
  height: 55px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0px;
  width: 100%;
  border: 0.2px solid rgba(62, 62, 62, 0.3);
  /* border-bottom-width: 1px !important; */
}

.otp_iout_indie {
  border-top: none;
  border-left: none;
}

.react_tele .react-tel-input .country-list {
  width: 285px !important;
}

.inout_picker_to_div {
  max-width: 55px !important;
  margin-top: 20px !important;
}

@media only screen and (max-width: 575px) {
  .set_date_time_lfex .react-datepicker {
    display: flex !important;
  }

  /* .experience_calendar .react-datepicker
  {
    display: block !important;

  } */
  .set_date_time_lfex .react-datepicker__day-name,
  .set_date_time_lfex .react-datepicker__day,
  .set_date_time_lfex .react-datepicker__time-name {
    width: 1.4rem !important;
    line-height: 1.4rem !important;
  }

  .set_date_time_lfex
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}

.react_tele .react-tel-input {
  /* border: 0.2px solid rgba(62, 62, 62, 0.3); */
  background: #fff;
  height: 55px;
  border-radius: 0px !important;
}

.react_tele .react-tel-input input {
  height: 55px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0px;
  width: 100%;
  border: 0.2px solid rgba(62, 62, 62, 0.3);
  /* border-bottom-width: 1px !important; */
}

.otp_iout_indie {
  border-top: none;
  border-left: none;
}

.react_tele .react-tel-input .country-list {
  width: 285px !important;
}

.expr_tabe .rdt_TableHeadRow .rdt_TableCol:nth-child(5),
.expr_tabe .rdt_TableRow .rdt_TableCell:nth-child(5) {
  min-width: 200px !important;
}

.stays_tabe .rdt_TableHeadRow .rdt_TableCol:nth-child(3),
.stays_tabe .rdt_TableRow .rdt_TableCell:nth-child(3) {
  min-width: 200px !important;
}

.showallimg_heiht .item {
  max-height: 400px;
  overflow-y: hidden;
  min-height: 400px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.showallimg_heiht .item img {
  max-height: 400px;
  object-fit: contain;
  border-radius: 12.5px;
}

.video_div_heigt video {
  max-height: 400px;
  object-fit: cover;
}

.view_wher_caed {
  background-color: var(--orange);
  border: 1px solid var(--orange);
  color: #fff;
  padding: 5px 10px;
  border-radius: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: 20px;
  display: block;
  max-width: max-content;
  color: #fff;
}

.view_wher_caed:hover {
  background-color: transparent;
  color: var(--orange);
}

.where-card.order_radius_left_right {
  border-radius: 15px !important;
}

.no_data_host_exp {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
  font-weight: 600;
}

.datepicker_theme_flutter {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}

.inut_date_sign .react-datepicker-wrapper {
  width: 100%;
  position: relative;
}

.inut_date_sign .react-datepicker-wrapper::after {
  content: "";
  position: absolute;
  top: 20px;
  right: 10px;
  width: 15px;
  height: 15px;
  background: url(../images/icons/calendar_new.png);
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.inut_date_sign .react-datepicker-wrapper .react-datepicker__input-container,
.inut_date_sign
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  outline: 0px !important;
}

.min_hight_drop {
  min-height: calc(100vh - 200px);
}

.videsec_rela:hover #play_pause_btn {
  opacity: 1 !important;
}

.attached.attach_img_chat {
  max-width: 80px;
  border-radius: 5px;
  max-height: 80px;
  min-height: 80px;
  min-width: 80px;
  object-fit: cover;
  border: 1px solid #ccc;
  object-position: top;
}

.text_danger_error {
  color: red;
}

.cursor_eye {
  cursor: pointer;
}

.cursor_eye:hover {
  color: var(--orange);
}

.break_tetx_new_room {
  word-break: break-word;
  white-space: break-spaces;
}

.add_govt_id_formcheck .form-check {
  justify-content: flex-end !important;
}

.modal_trle_sta {
  color: var(--orange);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.fotgoe_pwd_lonk_btn {
  background-color: transparent !important;
  border: none !important;
  text-align: right;
}

.fotgoe_pwd_lonk_btn:hover {
  color: #000 !important;
}

.disabled_statedate_true .position-relative,
.disabled_statedate_false .position-relative {
  top: 9px;
}

@media (max-width: 480px) {
  .experience_filter_map_search_btn {
    top: 130px !important;
  }
}

@media (min-width: 576px) {
  /* .room_detail_date_picker {
    min-width: 400px !important;
  } */
}

/* 



@media(min-width:576px) and (max-width:991px){
  .modal-dialog{
  max-width: 96% !important;
  }
  .room_detail_date_picker{
    left: -5% !important;
  }
 
}

@media(min-width:576px) and (max-width:700px){
  .modal-dialog .col_card_in .inbtn{
    position: relative;
    left: 21px !important;
  }
}
@media(min-width:701px) and (max-width:800px){
  .modal-dialog .col_card_in .inbtn{
    position: relative;
    left: 29px !important;
  }
}
@media(min-width:801px) and (max-width:992px){
  .modal-dialog .col_card_in .inbtn{
    position: relative;
    left: 38px !important;
  }
} */

.depa_acti.ele_span {
  background-color: #fff !important;
  background: #fff !important;
  border-radius: 5px !important;
}

.arri_acti.ele_span {
  background-color: #fff !important;
  background: #fff !important;
  border-radius: 5px !important;
}

.active_ele_date .btn_trans_icon {
  padding: 6px 10px !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: green !important;
}

.react-datepicker__day--in-range {
  background-color: #f7f7f7 !important;
  color: #000 !important;
}

.react-datepicker__day--in-range.react-datepicker__day--range-start,
.react-datepicker__day--in-range.react-datepicker__day--range-end {
  background-color: #ec6043 !important;
  color: #fff !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range.react-datepicker__day--range-start.react-datepicker__day--outside-month,
.react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--outside-month,
.react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--outside-month:hover,
.react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--outside-month {
  background-color: #fff !important;
  color: #000 !important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background-color: #ec6043 !important;
  color: #fff !important;
}

.mt-100 {
  padding-top: 100px;
}

.custom_active_link {
  color: white !important;
  background-color: #ec6043 !important;
}

.cus_height_vh {
  height: 40vh;
}

.custom_breadcrumb_ovr_rt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_breadcrumb_ovr_rt .breadcrumb-item::before {
  display: none !important;
}

.custom_breadcrumb_ovr_rt .breadcrumb .cus_link {
  background-color: #445e64;
  padding: 10px 17px;
  border-radius: 3px;
  text-decoration: none;
  color: white;
  /* margin-bottom: 20px; */
  white-space: nowrap;
}

.custom_breadcrumb_ovr_rt .breadcrumb a:hover {
  background-color: #ec6043;
}

.custom_breadcrumb_ovr_rt .breadcrumb-item a {
  text-decoration: none !important;
  color: white !important;
}

.custom_breadcrumb_ovr_rt .breadcrumb {
  display: flex;
  gap: 20px !important;
  overflow-x: auto;
  flex-wrap: nowrap !important;
  margin-bottom: 25px;
  align-items: center;
  padding-bottom: 10px;
}

@media (max-width: 991px) {
  .profpagetop {
    padding-top: 44px !important;
  }
}

.contact_details {
  padding-top: 70px;
}

.checkout_listing_img {
  width: 280px;
  height: 280px;
  /* object-fit: contain; */
}

.contact-no {
  font-size: 20px;
  /* font-weight: 500; */
}

.home_phn_icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

/* .infi_custom_loader{

} */
.filter-copy-sec .infinite-scroll-component__outerdiv {
  display: flex;
  align-items: center;
  /* height: 100vh; */
  justify-content: center;
}

.breadcrumb_custom_slash {
  width: 17px;
  height: 17px;
  color: #445e64;
  min-height: 17px;
  min-width: 17px;
}

@media (max-width: 576px) {
  .custom_breadcrumb_ovr_rt .breadcrumb {
    gap: 5px !important;
  }
}

/* border: 1px dashed gray; */

.custom_img_icon {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.custom_img_icon img {
  width: 30px !important;
  height: 30px !important;
}

.custom_textarea_hi8 {
  min-height: 100px !important;
}

.yellownav .owl-nav .owl-next span {
  /* background: url("../images/landingPage/testimonials/right_arrow.png")
    no-repeat;
  background-size: 100% 100%;
  width: 40px;
  height: 40px; */
}

.yellownav .owl-nav .owl-prev span {
  /* background: url("../images/landingPage/testimonials/left_arrow.png")
    no-repeat;
  background-size: 100% 100%;
  width: 40px;
  height: 40px; */
  /* visibility: hidden; */
}

.yellownav .owl-nav .owl-prev span:after {
  /* content: "";
  background: url("../images/landingPage/testimonials/left_arrow.png") no-repeat;
  background-size: 100% 100%;
  width: 40px;
  height: 40px; */
}

.owl-nav button:after {
  /* content: "";
  background: url("../images/landingPage/testimonials/left_arrow.png") no-repeat;
  background-size: 100% 100%;
  width: 40px;
  height: 40px; */
}

.accordion-button:not(.collapsed)::after {
  /* transform: rotate(0deg) !important; */
}

/* .accordion-button::after {
  transform: rotate(0deg) !important;
}
.accordion-button.collapsed::after {
  transform: rotate(-180deg) !important;
} */
.split_modal_popup .modal-header {
  padding: 16px !important;
  border: none !important;
}

.split_modal_popup .modal-content {
  border-radius: 0.5rem !important;
}

.split_modal_popup .modal-footer {
  border: none !important;
}

/* custom toggle switch */
.custom_toggle_switch .switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 20px;
}

.custom_toggle_switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom_toggle_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom_toggle_switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom_toggle_switch input:checked + .slider {
  background-color: #ec6043;
}

.custom_toggle_switch input:focus + .slider {
  box-shadow: 0 0 1px #ec6043;
}

.custom_toggle_switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(17px);
}

/* Rounded sliders */
.custom_toggle_switch .slider.round {
  border-radius: 34px;
}

.custom_toggle_switch .slider.round:before {
  border-radius: 50%;
}

.profile_owl_carousal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.need_to_subs {
  color: var(--orange);
}

.no-of_subs-days {
  color: #747474 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: unset !important;
}

.mh-100vh-header_wishlist {
  min-height: calc(100vh - 450px);
}

.feature_proper .header_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature_proper .view-all-properties button {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 13px 19px;
  border-radius: 9px;
  background: #f5593e;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.pricepernight .user-logo {
  width: 70px;
  height: 70px;
}

.pricepernight .user-logo .user {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.pricepernight .user-details .name {
  font-size: 18px;
  font-weight: 600;
}

.price-details .mbr_price {
  font-size: 22px;
  font-weight: 500;
  color: var(--themeclr);
}

.price-details .mbr_price span {
  font-size: 18px;
}

.price-details .non_mbr_price {
  font-size: 17px;
  font-weight: 500;
  color: var(--themeclr);
  color: #747474;
}

.price-details .non_mbr_price span {
  font-size: 15px;
}

.price-breakdown h6 {
  font-weight: 600;
  font-size: 20px;
}

.price-breakdown p {
  font-weight: 500;
  font-size: 19px;
}

.price-breakdown p .amount {
  color: var(--themeclr);
}

.subs_list_guest_host_txt {
  color: var(--themeclr);
}

.img_half_last_img {
  filter: brightness(0.7);
}

/* background-color: white !important;
box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2); */

.exp-detail-custom_datepicker {
}

.cal_pick_dates_display {
  display: flex;
}

.cal_pick_dates_display .disabled_statedate_true {
  background-color: white !important;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}

.exp-detail-custom_datepicker .dd_menu_shadow_card {
  /* transform: unset !important; */
}

.exp-detail-custom_datepicker hr {
  background-color: #d0d0d0;
  opacity: 1;
  height: 3px !important;
  margin: 0.5rem 0px 0.2rem 0px !important;
}

.exp-detail-custom_datepicker .react-datepicker {
  border: none !important;
}

.exp-detail-custom_datepicker .react-datepicker__header {
  background-color: transparent !important;
}

.exp-detail-custom_datepicker .react-datepicker__header {
  border-bottom: none !important;
}

.exp-detail-custom_datepicker .react-datepicker__navigation-icon::before {
  border-color: var(--themeclr) !important;
}

.exp-detail-custom_datepicker .react-datepicker__day--range-end {
  border-radius: 0px 5px 5px 0px !important;
}

.exp-detail-custom_datepicker .react-datepicker__day--range-start {
  border-radius: 5px 0px 0px 5px !important;
}

.filter_country_cali_region {
  width: 250px !important;
}
.experience_calendar .react-datepicker {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: none !important;
}
.experience_calendar .react-datepicker__day--range-start {
  border-radius: 8px 0px 0px 8px !important;
}

.experience_calendar .react-datepicker__day--range-end {
  border-radius: 0px 8px 8px 0px !important;
}

.overview p {
  font-size: 18px;
  font-weight: 500;
}

.overview h2 {
  /* font-weight: 600; */
}

.overview img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.overview span {
  font-weight: 500;
  font-size: 18px;
}

.selectdate .disabled_statedate_true {
  box-shadow: none !important;
  padding: 0px 7px 0px 7px !important;
}

.selectdate .custom_hr {
  height: 3px;
}

.selectdate .custom_accor_body {
  padding: 0rem 1rem 1.25rem;
}

.guest-custom-detail .minus_div {
  width: 18px;
  height: 18px;
  font-size: 9px;
  color: black !important;
  border: 1px solid black !important;
  opacity: 1;
}

.guest-custom-detail .plus_div {
  width: 18px;
  height: 18px;
  font-size: 9px;
  color: black !important;
  border: 1px solid black !important;
  opacity: 1;
}

.guest-custom-detail .who_title {
  color: var(--themeclr) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.guest-custom-detail .number_div {
  font-size: 18px !important;
  color: black !important;
}

.guest-custom-detail .plus_div .fa {
  position: relative;
  /* top: -1px; */
}

.exp-detail-custom_datepicker .dd_menu_shadow_card.country_card {
  /* min-width: unset !important; */
  /* width: 100% !important; */
}

@media (max-width: 480px) {
  .selectdate .dates input {
    font-size: 16px !important;
  }

  .guest-custom-detail .number_div {
    font-size: 16px !important;
  }

  .guest-custom-detail .who_title {
    font-size: 13.5px !important;
  }

  .exp-detail-custom_datepicker hr {
    margin: 0px !important;
  }
}

@media (max-width: 450px) {
  .exp-detail-custom_datepicker .dd_menu_shadow_card.country_card {
    width: 220px !important;
  }
}

@media (min-width: 451px) and (max-width: 480px) {
  .exp-detail-custom_datepicker .dd_menu_shadow_card.country_card {
    width: 100% !important;
    left: 0px !important;
  }

  .exp-detail-custom_datepicker .react-datepicker {
    width: 100%;
  }

  .exp-detail-custom_datepicker
    .react-datepicker
    .react-datepicker__month-container {
    width: 50%;
  }
}

@media (min-width: 480px) and (max-width: 576px) {
  .exp-detail-custom_datepicker .dd_menu_shadow_card.country_card {
    width: 100% !important;
    left: unset !important;
  }

  .exp-detail-custom_datepicker .react-datepicker {
    width: 100%;
  }

  .exp-detail-custom_datepicker
    .react-datepicker
    .react-datepicker__month-container {
    width: 50%;
  }
}

@media (min-width: 481px) and (max-width: 1025px) {
  .exp-detail-custom_datepicker .react-datepicker {
    width: 100%;
  }

  .exp-detail-custom_datepicker .dd_menu_shadow_card.country_card {
    width: 100% !important;
    min-width: unset !important;
  }

  .exp-detail-custom_datepicker
    .react-datepicker
    .react-datepicker__month-container {
    width: 50%;
  }
}

@media (min-width: 1400px) {
  .exp-detail-custom_datepicker .react-datepicker {
    width: 100%;
  }

  .exp-detail-custom_datepicker
    .react-datepicker
    .react-datepicker__month-container {
    width: 50%;
  }

  .exp-detail-custom_datepicker .dd_menu_shadow_card.country_card {
    /* width: unset !important;
    min-width: unset !important;
    max-width: unset !important; */
    width: 100% !important;
    padding: 8px !important;
    min-width: unset !important;
  }

  .experience_calendar .react-datepicker__navigation--next {
    right: 50px !important;
    top: 17px !important;
  }

  .experience_calendar .react-datepicker__navigation--previous {
    left: 30px !important;
    top: 17px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1450px) {
  .exp-detail-custom_datepicker
    .filter_date_picker_cutom.room_detail_date_picker
    .react-datepicker__day-name,
  .exp-detail-custom_datepicker
    .filter_date_picker_cutom.room_detail_date_picker
    .react-datepicker__day,
  .exp-detail-custom_datepicker
    .filter_date_picker_cutom.room_detail_date_picker
    .react-datepicker__time-name {
    width: 1.1rem !important;
    height: 1.1rem !important;
    font-size: 11px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .exp-detail-custom_datepicker .react-datepicker {
    width: 100%;
  }

  .exp-detail-custom_datepicker
    .react-datepicker
    .react-datepicker__month-container {
    width: 50%;
  }

  .exp-detail-custom_datepicker .dd_menu_shadow_card.country_card {
    /* width: unset !important;
    min-width: unset !important;
    max-width: unset !important; */
    padding: 8px !important;
    width: 100% !important;
    min-width: unset !important;
  }

  .expdetails .amenities .type {
    font-size: 15px !important;
  }
}

@media (min-width: 1026px) and (max-width: 1199px) {
  .exp-detail-custom_datepicker .react-datepicker {
    width: 100%;
  }

  .exp-detail-custom_datepicker
    .react-datepicker
    .react-datepicker__month-container {
    width: 100%;
  }

  .exp-detail-custom_datepicker .dd_menu_shadow_card.country_card {
    /* width: unset !important;
    min-width: unset !important;
    max-width: unset !important; */
    padding: 8px !important;
    width: 100% !important;
    min-width: unset !important;
  }

  .exp-detail-custom_datepicker .dd_menu_shadow_card.country_card {
    height: 300px;
    overflow: auto;
  }
}

@media (max-width: 1025px) {
  .cal_pick_dates_display {
    align-items: center;
    /* justify-content: space-around; */
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .experience_calendar .react-datepicker__navigation--next {
    right: 30px !important;
    top: 17px !important;
  }

  .experience_calendar .react-datepicker__navigation--previous {
    left: 14px !important;
    top: 17px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .expdetails .amenities .type {
    font-size: 15px !important;
  }
}

@media (min-width: 1200px) {
  .experience_calendar .react-datepicker {
    padding: 10px !important;
  }
}

@media (max-width: 768px) {
  .price-details .mbr_price {
    font-size: 19px !important;
  }

  .price-details .mbr_price span {
    font-size: 16px !important;
  }

  .price-details .non_mbr_price {
    font-size: 14px !important;
  }

  .price-details .non_mbr_price span {
    font-size: 12px !important;
  }
}

.no-data-found {
  font-size: 23px !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plansrow_sub {
  min-height: calc(100vh - 400px);
  display: flex;
  align-items: center;
  justify-content: center;
}
/* karthick css */
.header_modal i {
  font-size: 25px;
  cursor: pointer;
}

.fc .fc-bg-event {
  background: #ff0000 !important;
}

.img_upload_zindex {
  z-index: 1;
}

.img_uipoad_div_cen {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.img_uipoad_div_cen img {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

/* end of karthick css */
/* end of karthick css */

.sub-image .col {
  padding: 0px;
}

.view-all-mobile {
  display: none;
  text-align: center;
}
.view-all-web {
  display: block;
}

.view-all-mobile button {
  border-radius: 4px !important;
  padding: 12px 20px !important;
}

.amenties-card {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  /* justify-content: space-between; */
  gap: 10px;
}

.amenties-card .cmn-card {
  min-width: 23%;
  /* margin-bottom: 10px; */
}
.terms-conditions p {
  font-weight: 400;
}
.terms-conditions ul li {
  font-weight: 400;
}
.terms-conditions .custom-list {
  list-style-type: none;
  margin-bottom: 0px;
  padding-left: 1rem;
}
.terms-conditions .custom-list li:before {
  content: "\2013";
  position: absolute;
  margin-left: -15px;
  margin-top: 2px;
}

.notification-card {
  background-color: #e3f5ff;
  border-radius: 13px;
  padding: 20px;
}

.yourDash_tabs .container {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
  /* padding-bottom: 10px; */
  overflow-x: auto;
  background-color: white;
}

.yourDash_tabs .heading-cnt {
  text-wrap: nowrap;
  font-weight: 600;
  cursor: pointer;
  padding: 7px 20px;
  background-color: transparent;
  border-radius: 8px;
}

.yourDash_tabs .heading-cnt:hover {
  background-color: #e3f5ff;
}
.yourDash_tabs .heading-cnt.active {
  background-color: #e3f5ff;
}


.dashboard-today-sec .stick .yourDash_tabs {
  position: -webkit-sticky; 
  position: sticky;
  top: -1px; 
  z-index: 2;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.finish-setup .custom-card {
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  height: 100%;
}

.room-detail.shadow-box {
  padding: 15px;
  box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.room-detail.shadow-box .lite-gray {
  color: #747474;
  font-weight: 700;
}

.room-detail.shadow-box .amount-dly {
  background-color: #f5593e;
  border-radius: 5px;
  padding: 5px 15px;
  color: #fff;
  font-size: 14px;
}

.house-rules .rule-heading {
  color: var(--themeclr1);
  font-weight: 500;
  margin-bottom: 4px !important;
}

.house-rules .cmn-plus-minus {
  background-color: transparent !important;
  color: #000 !important;
  border: none !important;
  outline: none;
  box-shadow: none !important;
  padding: 0px;
  font-weight: 600;
}

.house-rules .additional-rules {
  border-radius: 5px;
  border: 1px solid var(--themeclr1);
  padding: 5px 10px;
  width: 50%;
}

.additional-rules .edit {
  color: var(--themeclr1);
}

.additional-rules .edit:hover {
  color: var(--black);
}

.custom-host-drop .dropdown-toggle {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent !important;
  color: var(--black) !important;
  border: 1px solid var(--themeclr1) !important;
  font-size: 14px;
  font-weight: 500;
}

.custom-host-drop button::after {
  color: var(--themeclr1);
}

.custom-host-drop .dropdown-menu {
  background-color: transparent !important;
  border: none !important;
}

.custom-host-drop .react-datepicker__input-container {
  display: none;
}

.custom-host-drop .react-datepicker {
  border-radius: 10px !important;
  box-shadow: 0 1px 2px #00000014, 0 4px 12px #0000000d;
  border: none;
}
.custom-host-drop .react-datepicker__month-container {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}
.custom-host-drop .react-datepicker__header {
  background-color: var(--white) !important;
}
.custom-host-drop .react-datepicker__triangle {
  display: none;
}
.custom-host-drop .react-datepicker-popper {
  transform: translate(0px, 0px) !important;
}

.setrule .discount-percent {
  border: 1px solid var(--themeclr1);
  border-radius: 5px !important;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
  width: 60%;
}

.setrule .discount-percent:disabled {
  border: 1px solid #d9d9d9 !important;
  opacity: 0.7;
}
.promote-list-btns {
  width: 60%;
  justify-content: end;
  gap: 15px;
}
.promote-list-btns button {
  padding: 6px 13px;
  border-radius: 6px;
}

.dash-header .logo-txt {
  color: var(--themeclr1);
  font-size: 33px;
  font-weight: 700;
}

.dash-header .rightheader .dropdown > a {
  border: none !important;
  width: 30px !important;
  height: 30px !important;
  padding: 0px !important;
}

.themedrop.bell .dropdown-toggle {
  background-color: var(--themeclr1);
  color: var(--white);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-page .search-btn {
  position: relative;
}
.chat-page .search-btn .form-control {
  border: 1px solid var(--themeclr1);
  color: var(--black);
  font-weight: 500;
  /* font-size: 17px; */
}
.chat-page .search-btn .form-control::placeholder {
  color: var(--black);
  font-size: 16px;
}

.chat-page .search-btn .fa-search {
  position: absolute;
  right: 11px;
  top: 13px;
  color: var(--themeclr1);
}

.chat-page .user-profile .logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.chat-page .user-profile .profile-name {
  color: var(--black);
  font-weight: 700;
}
.calendar-page .fc-toolbar-title {
  margin-bottom: 0px !important;
}
.calendar-page .fc .fc-toolbar {
  justify-content: unset !important;
  gap: 20px;
}
.calendar-page .fc .fc-toolbar .fc-toolbar-chunk:nth-child(2) {
  display: none;
}

.calendar-page .fc-today-button {
  background-color: var(--themeclr1) !important;
  border: 1px solid var(--themeclr1) !important;
  text-transform: capitalize;
  font-weight: 600;
  padding: 6px 20px !important;
  border-radius: 4px !important;
}

.calendar-page .fc-prev-button {
  background-color: transparent !important;
  color: var(--themeclr1) !important;
  border: 1px solid var(--themeclr1) !important;
  border-radius: 6px !important;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar-page .fc-next-button {
  background-color: transparent !important;
  color: var(--themeclr1) !important;
  border: 1px solid var(--themeclr1) !important;
  border-radius: 6px !important;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar-page .fc-button-group {
  gap: 12px;
}
.calendar-page .fc-prev-button:hover {
  color: var(--white) !important;
}
.calendar-page .fc-next-button:hover {
  color: var(--white) !important;
}

.calendar .stikybtns {
  height: unset !important;
}

.calendar .stikybtns .btn-success {
  border: 1px solid var(--themeclr1) !important;
  color: var(--themeclr1) !important;
  border-radius: 4px !important;
}

.availability .themehr {
  background-color: rgba(245, 89, 62, 0.8) !important;
  height: 2px;
  margin: 0.5rem 0px;
}

.availability .price-ft-btn button {
  padding: 6px 15px !important;
  border-radius: 6px !important;
  font-size: 15px;
}

.cmn-table-webView {
  box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0px 10px;
}
.cmn-table-webView .rdt_Pagination {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.cmn-table-shadow .custom-nav-link {
  background-color: transparent !important;
  color: var(--themeclr1) !important;
  font-weight: 600;
  font-size: 17px;
}
.no-data-bdr-top {
  border-top: 1px solid rgba(245, 89, 62, 0.2);
}
.no-data-bdr-top .hDHZZh div {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
}

.cmn-data-table .rdt_TableHeadRow .rdt_TableCol {
  background-color: transparent;
  color: var(--black);
}
.cmn-data-table .rdt_TableHeadRow {
  border-top: 1px solid rgba(245, 89, 62, 0.2);
  border-bottom: 2px solid rgba(245, 89, 62, 0.8);
  padding: 0px !important;
}

.cmn-data-table .lnOUep {
  text-transform: capitalize;
}
.cmn-data-table .cus-btn-link {
  color: var(--themeclr1);
  text-decoration: underline;
  font-size: 13px;
}
.cmn-data-table .cus-btn-link:hover {
  color: var(--black);
}
.cus-btn-link-black {
  color: var(--black);
  text-decoration: underline;
  font-size: 13px;
}
.cus-btn-link-black:hover {
  color: var(--themeclr1);
}
.table-datas .view-details {
  background-color: transparent !important;
  border: none !important;
  outline: none;
  box-shadow: none;
  padding: 0px !important;
  font-weight: 500;
  font-size: 14px;
}

.showing-records {
  font-size: 14px;
  padding: 0px 0px 0px 15px;
  /* margin-top: -37px; */
  position: relative;
  top: -37px;
  font-weight: 400;
}

.cmn-table-mblView .table-heading {
  color: var(--themeclr1);
}

.cmn-table-mblView .datas {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
}

.cmn-table-mblView .table-datas .name {
  color: var(--themeclr1);
  font-size: 15px;
  font-weight: 500;
}
.cmn-table-mblView .table-datas .value {
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
}
.cmn-table-mblView .prev-next-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cmn-table-mblView .prev-next-btn button {
  background-color: transparent !important;
  color: var(--themeclr1) !important;
  border: 1px solid var(--themeclr1) !important;
  border-radius: 6px !important;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.cmn-table-mblView .prev-next-btn button:hover {
  background-color: var(--themeclr1) !important;
  color: var(--white) !important;
}

.stay-togglebtn .btn {
  color: var(--themeclr1);
}

.stay-togglebtn .active.btn {
  color: var(--themeclr1);
  font-weight: 500;
  text-decoration: underline;
}

.logo-txt {
  color: var(--themeclr1);
  font-size: 35px;
  font-weight: 700;
}
.become-host {
  color: var(--themeclr1) !important;
  text-decoration: underline;
  background-color: transparent !important;
  border: none !important;
  outline: none;
  box-shadow: none;
  font-weight: 500;
}
.become-host:hover {
  color: var(--black) !important;
}
.become-host:after {
  display: none;
}
.cus-ham-burger {
  border: 1px solid #e5e5e5;
  padding: 5px 15px;
  border-radius: 7px;
}
.cus-ham-burger i {
  color: #737373;
}
.acc-header-logo {
  width: 65px;
}

/* Profile page */

.personalinfo .header-txt {
}

.edit-your-profile {
  padding: 25px 25px;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
}

.edit-your-profile .profile-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.personalinfo .prof-btns {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
}
.personalinfo .prof-btns button {
  padding: 6px 20px;
}

.custom-upload-img .plus-icon {
  position: absolute;
  top: 50%;
  transform: translate(-47%, -45%);
  left: 50%;
  color: var(--themeclr1);
}

.custom-upload-img {
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}
.custom-upload-img .upload-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  background-color: #dff6f9 !important;
  border: none !important;
  outline: none;
}
.custom-upload-img input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  z-index: 2;
  position: relative;
  padding: -2px;
  border-radius: 50%;
  display: inline-block;
  padding: 5px;
  opacity: 0 !important;
  cursor: pointer;
}
.personal-info .label {
  margin-bottom: 10px;
}
.personal-info .form-control {
  border: 1px solid var(--themeclr1) !important;
  border-radius: 6px !important;
  color: var(--black) !important;
  font-size: 14px;
  font-weight: 400;
}
#messages.chat-inbox .header .container {
  min-width: 98% !important;
}
.hosthead a.nav-link {
  color: var(--themeclr1) !important;
}
.hosthead a.nav-link.active {
  font-weight: 600;
}

.custom-dash-header .cus-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.custom-dash-header {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.dash-header-mbl-navlink .nav-link {
  background: #f2f2f2;
  padding-left: 10px !important;
  margin-bottom: 5px;
  color: var(--themeclr1);
}
.dash-prof-drop .dropdown-menu {
  left: auto !important;
  right: 0px !important;
  top: auto !important;
}

/* host room */

.hstrm-prv-nxt-btn {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: end;
}
.hstrm-prv-nxt-btn button {
  padding: 7px 15px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.ur-plc-card {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
}
.ur-plc-card.active {
  box-shadow: 0px 0px 0px 3px #f5593e;
}
.ur-plc-card:hover {
  box-shadow: 0px 0px 0px 3px #f5593e;
}
.ur-plc-card .room-img {
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  height: 230px;
  min-height: 230px;
}

.ur-plc-card .desc-sec {
  padding: 15px 10px;
}

.ur-plc-card.amenity {
  cursor: pointer;
  min-height: 150px;
  padding: 20px;
}

.ur-plc-card.amenity img {
  height: 30px;
}

.map-section .location-details {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px 30px;
}

.map-section .addressInp {
  border: 1.5px solid #f5593e;
  /* border: 1.5px solid #b9b9b9; */
  padding: 0px 12px 0px 13.04px;
  border-radius: 5px;
  height: 40px;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
}
.map-section .addressInp::placeholder {
  color: #b9b9b9;
}

.map-section .useMyLoc {
  color: #f5593e;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.map-section .useMyLoc:hover {
  color: var(--black);
}

.roomListings .cmn-Inc-Dec {
  width: 20px;
  height: 20px;
  border: 1px solid var(--black) !important;
  color: var(--black) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 500 !important;
  cursor: pointer;
}

.roomListings .cmn-Inc-Dec .fa {
  font-size: 10px;
}

.cancellationPolicy .form-check {
  display: unset !important;
  padding-left: 0.7rem;
}
.cancellationPolicy .form-check .form-check-input {
  margin-left: unset;
}
.cancellationPolicy .form-check-label {
  font-weight: 400;
  font-size: 15px;
}

.custom-uploadImgBtn {
  width: 100%;
  height: 100%;
  background: transparent !important;
  padding: 0;
  border: 2px dashed #7b7b7b !important;
  position: relative;
}

.custom-uploadImgBtn.bigImg .pickedImg {
  min-height: 400px;
  max-height: 400px;
  height: 100%;
  width: 100%;
  background-color: #e8e8e8;
  border-radius: 4px;
}

.custom-uploadImgBtn.smallImg .pickedImg {
  min-height: 196px;
  max-height: 196px;
  height: 100%;
  width: 100%;
  background-color: #e8e8e8;
  border-radius: 4px;
}

.custom-uploadImgBtn .pickedImg .choosed-img {
  width: 100%;
  height: 100%;
}

.custom-uploadImgBtn .hiddenInput {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 9;
  opacity: 0;
}

.custom-uploadImgBtn .hiddenInput .uploadInp {
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
}

.custom-uploadImgBtn .upload-txt {
  color: var(--black);
  font-size: 15px;
}

.custom-uploadImgBtn .withoutImg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.custom-uploadImgBtn.bigImg .upload-img {
  height: 100px;
}
.custom-uploadImgBtn.smallImg .upload-img {
  height: 60px;
}

.listing-details {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px 30px;
}

.listing-details .listingInp {
  border: 1.5px solid #f5593e;
  /* border: 1.5px solid #b9b9b9; */
  padding: 0px 12px 0px 13.04px;
  border-radius: 5px;
  height: 40px;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  padding: 10px 15px;
}
.listing-details .listingInp.txtarea {
  height: 130px;
}

.setYourPrice .priceInp {
  border: 1.5px solid #f5593e;
  /* border: 1.5px solid #b9b9b9; */
  padding: 0px 12px 0px 13.04px;
  border-radius: 5px;
  height: 40px;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  width: 300px;
  padding: 10px 15px;
}

.describeYour-place {
  /* width: 100%; */
  /* height: 350px; */
  /* overflow-y: auto; */
  /* padding: 10px; */
}
.placeOffer {
  /* width: 100%; */
  /* height: 350px; */
  /* overflow-y: auto; */
  /* padding: 10px; */
}
.standoutAmenities {
  /* width: 100%; */
  /* height: 350px; */
  /* overflow-y: auto; */
  /* padding: 10px; */
}

.propstype .header-txt h2 {
  font-size: 40px;
}
.propstype .header-txt .des-txt {
  font-size: 17px;
  font-weight: 500;
}

/* preview listing */

.preview-listing .CreateListing_btn {
  font-size: 15px !important;
  padding: 8px 20px !important;
}
.preview-listing .back_btn {
  font-size: 15px !important;
  padding: 8px 20px !important;
}

.preview-listing .header-bdm-hr {
  background-color: rgba(245, 89, 62, 1);
}

.hostRoom-header .head-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hostRoom-header .rightSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hostRoom-header .rightSec .headProf {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.hostRoom-header .rightSec .notification .dropdown-toggle {
  background-color: var(--themeclr1);
  color: var(--white);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hostRoom-header .rightSec .notification .dropdown-toggle:hover {
  background-color: var(--black) !important;
}

.hostRoom-header .cus-hamBurger {
  border: 1px solid #e5e5e5;
  padding: 3px 12px;
  border-radius: 7px;
}
.hostRoom-header .cus-hamBurger .fa-bars {
  font-size: 13px;
  color: #737373;
}
.hostRoom-header .notification > a:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0f3";
}
.hostRoom-header .notification > a:after {
  display: none;
  content: "\f0f3";
}

.custom-dash-header .hostRoom-drop {
  right: 0px !important;
  left: auto !important;
}
.custom-dash-header .notification .dropdown-menu {
  right: 0px !important;
  left: auto !important;
}

.headerTwo .userImg {
  width: 31px;
  height: 31px;
  border-radius: 50%;
}

.headerTwo .cus-ham-Burger {
  border: 1px solid #e5e5e5;
  padding: 5px 15px;
  border-radius: 7px;
  color: #737373;
}
.headerTwo .cusMbl-ham-burger {
  border: 1px solid #e5e5e5;
  padding: 1px 10px;
  border-radius: 7px;
  color: #737373;
}
.headerTwo .cusMbl-ham-burger .fa.fa-bars {
  font-size: 14px;
}
.custom-dash-header .headerprof {
  height: 31px !important;
  width: 31px !important;
}
.custom-dash-header .cus-ham-burger {
  padding: 3px 12px;
}
.custom-dash-header .cus-ham-burger .fa-bars {
  font-size: 14px;
}

.account-header .profileDrop {
  right: 0px !important;
  left: auto !important;
  transform: translate(0px, 40px) !important;
}

.account-header .becomeHost {
  right: 0px !important;
  left: auto !important;
  transform: translate(0px, 30px) !important;
}

.property-header .new_navbar {
  top: 0px;
}
.property-header .navbar_default_bg {
  margin-top: 0px !important;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.expdetails .detail_ful {
  margin-top: 90px !important;
}

.terms-of-services {
  margin-top: 90px;
}
.property-header .new_navbar .logo_txt_blk.org {
  color: #f5593e !important;
}

.property-header .navbar-toggler.collapsed .navbar-toggler-icon {
  width: 14px;
  height: 13px;
  background: url("../images/nav-collapsed.png") no-repeat;
  background-size: 100% 100%;
}
.property-header .navbar-toggler .navbar-toggler-icon {
  width: 14px;
  height: 14px;
  background: url("../images/nav-not-collapsed.png") no-repeat;
  background-size: 100% 100%;
}

.useLocation_map {
  width: unset !important;
  position: unset;
}

.map-section .custom_map_pos {
  position: absolute;
  top: 20px;
  left: 10px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.edit_profileModal textarea {
  width: 100%;
  height: 100px;
  /* border: 1px solid var(--themeclr1) !important; */
  /* border: 1px solid var(--themeclr1) !important; */
  border-radius: 6px !important;
  color: var(--black) !important;
  font-size: 14px;
  font-weight: 400;
}

/* Media query */

@media (max-width: 479px) {
  .img_half_height {
    min-height: 130px;
    max-height: 130px;
  }
  .show-all-img .show_all_btn {
    font-size: 15px !important;
  }
  .amenties-card .cmn-card {
    min-width: 46%;
  }
  .custom-host-drop .dropdown-toggle {
    width: 100%;
  }
  .setrule .discount-percent {
    width: 100%;
  }
  .promote-list-btns {
    width: 100%;
  }
  .showing-records {
    position: unset;
    text-align: center;
    margin-bottom: 10px;
  }
  .edit-your-profile {
    padding: 25px 20px;
  }
  .edit-your-profile .head-sec {
    flex-direction: column;
    align-items: start !important;
    gap: 20px !important;
  }
  .membership-deatils h5 {
    font-size: 18px;
  }
  .membership-deatils p {
    font-size: 15px;
  }
  .person_info_edit .cmn-btn {
    font-size: 14px;
  }
  .person_info_edit .header-desc {
    font-size: 15px;
  }
  .person_info_edit .prof-desc h5 {
    font-size: 18px;
  }
  .person_info_edit .prof-desc p {
    font-size: 15px;
  }
  .header-dash-container .navbar-brand {
    width: 90%;
  }
  .map-section .location-details {
    padding: 20px 20px;
  }
  .custom-uploadImgBtn.bigImg .pickedImg {
    min-height: 250px;
    max-height: 250px;
    height: 100%;
  }
  .custom-uploadImgBtn.smallImg .pickedImg {
    min-height: 120px;
    max-height: 120px;
    height: 100%;
  }
  .custom-uploadImgBtn.bigImg .upload-img {
    height: 70px;
  }
  .custom-uploadImgBtn.smallImg .upload-img {
    height: 45px;
  }
  .custom-uploadImgBtn .upload-txt {
    font-size: 13px;
  }
  .listing-details {
    padding: 20px 20px;
  }
  .listing-details .listingInp.txtarea {
    height: 100px;
  }
  .setYourPrice .priceInp {
    width: 100%;
  }
  .propstype .header-txt h2 {
    font-size: 25px;
  }
  .propstype .header-txt .des-txt {
    font-size: 15px;
  }
  .yourDash_tabs .container {
    gap: 5px;
  }
}
@media (min-width: 480px) and (max-width: 575px) {
  .img_half_height {
    min-height: 150px;
    max-height: 150px;
  }
  .amenties-card .cmn-card {
    min-width: 46%;
  }
  .custom-host-drop .dropdown-toggle {
    width: 80%;
  }
  .setrule .discount-percent {
    width: 100%;
  }
  .promote-list-btns {
    width: 100%;
  }
  .header-dash-container .navbar-brand {
    width: 90%;
  }
  .map-section .location-details {
    padding: 20px 20px;
  }

  .custom-uploadImgBtn.bigImg .pickedImg {
    min-height: 300px;
    max-height: 300px;
    height: 100%;
  }
  .custom-uploadImgBtn.smallImg .pickedImg {
    min-height: 150px;
    max-height: 150px;
    height: 100%;
  }
  .custom-uploadImgBtn.bigImg .upload-img {
    height: 70px;
  }
  .custom-uploadImgBtn.smallImg .upload-img {
    height: 45px;
  }
  .custom-uploadImgBtn .upload-txt {
    font-size: 14px;
  }
  .listing-details {
    padding: 20px 20px;
  }
  .listing-details .listingInp.txtarea {
    height: 100px;
  }
  .propstype .header-txt h2 {
    font-size: 28px;
  }
  .propstype .header-txt .des-txt {
    font-size: 16px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .img_half_height {
    min-height: 180px;
    max-height: 180px;
  }
  .amenties-card .cmn-card {
    min-width: 31%;
  }
  .common-checking-card .no-stat p {
    font-size: 16px;
  }
  .modalright a {
    padding: 14px;
    font-size: 16px;
  }
  .common-dash-guest .header-sec .header-txt {
    font-size: 24px;
  }
  .common-dash-guest .header-sec .header-desc {
    font-size: 15px;
  }
  .house-rules .additional-rules {
    width: 60%;
  }
  .acc-header-logo {
    width: 40px;
  }
  .become-host {
    font-size: 15px;
  }
  .header-right-head {
    margin-top: 0px !important;
  }
  .acc-header-prof {
    width: 35x !important;
    height: 35px !important;
  }
  .logo-txt {
    font-size: 22px;
  }
  .header-dash-container .navbar-brand {
    width: 93%;
  }
  .custom-uploadImgBtn.bigImg .pickedImg {
    min-height: 300px;
    max-height: 300px;
    height: 100%;
  }
  .custom-uploadImgBtn.smallImg .pickedImg {
    min-height: 170px;
    max-height: 170px;
    height: 100%;
  }
  .custom-uploadImgBtn.bigImg .upload-img {
    height: 70px;
  }
  .custom-uploadImgBtn.smallImg .upload-img {
    height: 45px;
  }
  .custom-uploadImgBtn .upload-txt {
    font-size: 14px;
  }
  .propstype .header-txt h2 {
    font-size: 30px;
  }
  .propstype .header-txt .des-txt {
    font-size: 16px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .img_half_height {
    min-height: 180px;
    max-height: 180px;
  }
  .amenties-card .cmn-card {
    min-width: 23%;
  }
  .modalright a {
    padding: 14px;
    font-size: 16px;
  }
  .common-dash-guest .header-sec .header-txt {
    font-size: 24px;
  }
  .common-dash-guest .header-sec .header-desc {
    font-size: 15px;
  }
  .house-rules .additional-rules {
    width: 100%;
  }
  .acc-header-logo {
    width: 40px;
  }
  .become-host {
    font-size: 15px;
  }
  .header-right-head {
    margin-top: 0px !important;
  }
  .acc-header-prof {
    width: 35x !important;
    height: 35px !important;
  }
  .logo-txt {
    font-size: 22px;
  }
  .header-dash-container .navbar-brand {
    width: 95%;
  }
  .custom-uploadImgBtn.bigImg .pickedImg {
    min-height: 350px;
    max-height: 350px;
    height: 100%;
  }
  .custom-uploadImgBtn.smallImg .pickedImg {
    min-height: 200px;
    max-height: 200px;
    height: 100%;
  }
  .custom-uploadImgBtn.bigImg .upload-img {
    height: 80px;
  }
  .custom-uploadImgBtn.smallImg .upload-img {
    height: 55px;
  }
  .custom-uploadImgBtn .upload-txt {
    font-size: 15px;
  }
  .propstype .header-txt h2 {
    font-size: 30px;
  }
  .propstype .header-txt .des-txt {
    font-size: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .amenties-card .cmn-card {
    min-width: 23%;
  }
  .house-rules .additional-rules {
    width: 80%;
  }
  .acc-header-logo {
    width: 50px;
  }
  .become-host {
    font-size: 16px;
  }
  .header-right-head {
    margin-top: 0px !important;
  }
  .acc-header-prof {
    width: 40px !important;
    height: 40px !important;
  }
  .logo-txt {
    font-size: 27px;
  }
  /* .header-dash-container .navbar-brand {
    width: 100%;
  } */
  .custom-uploadImgBtn.bigImg .upload-img {
    height: 80px;
  }
  .custom-uploadImgBtn.smallImg .upload-img {
    height: 45px;
  }
  .custom-uploadImgBtn .upload-txt {
    font-size: 14px;
  }
  .propstype .header-txt h2 {
    font-size: 31px;
  }
  .propstype .header-txt .des-txt {
    font-size: 16px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .amenties-card .cmn-card {
    min-width: 23%;
  }
  /* .header-dash-container .navbar-brand {
    width: 100%;
  } */
  .propstype .header-txt h2 {
    font-size: 35px;
  }
  .propstype .header-txt .des-txt {
    font-size: 16px;
  }
}
@media (min-width: 1400px) and (max-width: 1800px) {
}
@media (min-width: 1801px) {
}
@media (max-width: 575px) {
  .experience_calendar .react-datepicker {
    box-shadow: none !important;
  }
  .expdetails .logo {
    display: none;
  }
  .view-all-mobile {
    display: block;
    text-align: center;
  }
  .view-all-web {
    display: none;
  }
  .our-featured-card .cus_card {
    margin-top: 0px !important;
  }
  .common-checking-card .no-stat {
    padding: 10px;
  }
  .common-checking-card .no-stat p {
    font-size: 14px;
  }
  .common-dash-guest .header-sec .header-txt {
    font-size: 23px;
  }
  .host-dashboard-img-sec {
    width: unset;
  }
  .modalright a {
    font-size: 18px;
    padding: 14px;
  }
  .house-rules .additional-rules {
    width: 100%;
  }
  .house-rules .header-desc {
    font-size: 15px;
  }

  .cmn-table-webView {
    display: none;
  }
  .cmn-table-mblView {
    display: block;
  }
  .togglebtn-exp button {
    padding: 10px 20px !important;
  }
  .table-datas .host-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .table-no-records {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
  }
  .logo-txt {
    color: var(--black);
    font-size: 19px;
  }
  .cus-ham-burger {
    display: none;
  }
  .acc-header-logo {
    width: 30px;
  }
  .become-host {
    font-size: 13px;
  }
  .header-right-head {
    margin-top: 0px !important;
  }
  .acc-header-prof {
    width: 30px !important;
    height: 30px !important;
  }
}
@media (min-width: 576px) {
  .calendar .stikybtns .select-room-drop {
    position: relative;
    top: 37px;
  }
  .cmn-table-webView {
    display: block;
  }
  .cmn-table-mblView {
    display: none;
  }
}
@media (max-width: 575px) {
  .hostRoom-header .cus-hamBurger {
    display: none;
  }
  .hostRoom-header .notification {
    display: none;
  }
  .expdetails .detail_ful {
    margin-top: 80px !important;
  }
  .terms-of-services {
    margin-top: 80px;
  }
}
@media (max-width: 767px) {
}
@media (max-width: 991px) {
  .header-dash-container {
    flex-wrap: nowrap;
  }
  .header-dash-container .navbar-brand {
    margin-right: 0px;
  }
  .header-dash-container .become-host {
    font-size: 10px;
  }
  .header-dash-container .navbar-toggler-icon {
    width: 15px;
    height: 15px;
  }
  .header-dash-container .navbar-toggler {
    padding: 1px 6px;
    box-shadow: none;
  }
  .header-dash-container .header-right-head {
    margin: 0px 7px;
  }
  .header-dash-container .mbl-view-heading {
    justify-content: space-between;
  }
  .header-dash-container .offcanvas-body .header-right-head {
    display: none;
  }
  .hosthead {
    display: none !important;
  }
  .custom-dash-header-one .navbar-toggler {
    display: none;
  }
  .custom-dash-header-one .header-dash-container {
    display: unset !important;
  }
  .headerTwo .mbl-offCanvas {
    display: none;
  }
  .headerTwo .header-dash-container {
    display: unset;
  }
}
@media (min-width: 992px) {
  .custom-dash-header .collapse:not(.show) {
    display: none !important;
  }
  .custom-dash-header .offcanvas-body {
    justify-content: end;
  }
  .mbl-view-heading .right-head {
    display: none;
  }
  .header-dash-container .offcanvas-body {
    justify-content: end;
  }
  .dash-header-mbl-navlink {
    display: none;
  }
  .header-dash-container .offcanvas-header {
    display: none !important;
  }
}
@media (max-width: 1199px) {
  .cus-ham-burger {
    display: none;
  }
}
@media (max-width: 1399px) {
}
